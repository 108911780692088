import React, { useState, useEffect } from 'react'
import http from '../../CommonAxios'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import Slider from "react-slick";


const EventGallery = () => {
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [viewImage, setViewImage] = useState('');
    const toggleView = (name) => {
        setViewModal(!viewModal);
        if (checkUndeNullBlank(name)) {
            setViewImage(name);
        }

    }
    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`/event/gallery/front_list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
        window.scrollTo(0, 0)
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };

    return (
        <div className="element">
            <div class="breadcumb-wrapper background-image">
                <img src={`${process.env.REACT_APP_BASE_URL}/img/Gallery-Banner.jpg`} alt="banner" className="w-100 img-fluid" />
            </div>
            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" isOpen={viewModal} toggle={viewModal} scrollable={true} className='addModal'>
                <ModalHeader toggle={toggleView} />
                <ModalBody className="d-flex justify-content-center">
                    <img src={`${viewImage}`} alt="meeting" />
                </ModalBody>
            </Modal>

            {checkData(apiData) && checkData(apiData.data) ? apiData.data.map((item, index) => (
                <div class="teacher-area padd">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="section-title text-center">
                                    <h2 class="sec-title">{item.event_title}</h2>
                                    <ul class="section-line">
                                        <li class="first"></li>
                                        <li class="second"></li>
                                        <li class="third"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="teacher-active owl-carousel owl-theme owl-loaded pb-5 mb-5">
                                <div class="owl-stage-outer">
                                    <div class="owl-stage">
                                        <Slider {...settings} slidesToShow={checkData(item.event_galleries) && Object.keys(item.event_galleries).length > 3 ? 4 : checkData(item.event_galleries) ? Object.keys(item.event_galleries).length : ''}>
                                            {checkData(item.event_galleries) ? item.event_galleries.map((items, key) => (
                                                <div class="owl-item cloned" style="width: 292.5px; margin-right: 0px;">
                                                    <div class="col-xs-12 portfolio sports event">
                                                        <div class="teacher-wrap portfolio-items">
                                                            <div class="teacher-img">
                                                                <div class="img-container">
                                                                    {checkUndeNullBlank(items.image) ?
                                                                        <img class="img-radius1" onClick={() => toggleView(`${apiData.path}/${items.image}`)} src={`${apiData.path}/${items.image}`} alt="team image" /> :
                                                                        <img class="img-radius1" src="./img/team_1_1.jpg" alt="team image" />
                                                                    }
                                                                    <div class="overlay-div d-none">
                                                                        <div class="text-div">
                                                                            <a class="enlarge-icon-div popup" href="assets/img/newimg/gallary-img/3Y5A5244.jpg">
                                                                                <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : ''}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )) :
                <div class="teacher-area padd my-5">
                </div>
            }
        </div>
    );
};

export default EventGallery;