import React, { useState, useEffect } from 'react'
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import Slider from "react-slick";

const Team = () => {
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`/speaker/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        // slidesToShow: 4,
        slidesToShow: checkData(apiData) && checkData(apiData.data) && Object.keys(apiData.data).length > 2 ? 4 : checkData(apiData) && checkData(apiData.data) ? Object.keys(apiData.data).length : '',
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: checkData(apiData) && checkData(apiData.data) && Object.keys(apiData.data).length > 1 ? 2 : checkData(apiData) && checkData(apiData.data) ? Object.keys(apiData.data).length : '',
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section class="speakers-section">
            <div class="anim-icons full-width">
                <span class="icon icon-dotted-circle"></span>
            </div>
            <div class="container position-relative">
                <div class="sec-title text-center position-relative">
                    <h2>Meet Our<span className='newText'>Speakers</span></h2>
                    <span class="sub-title">Speakers Who Are Experts in Their Fields</span>
                    <span class="divider"></span>

                </div>
                <div class="row">
                    <Slider {...settings} className="12">
                        {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (

                            <div class="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp animated">
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure class="image"><a href="#">
                                            {checkUndeNullBlank(item.image) ?
                                                <img src={`${apiData.path}/${item.image}`} alt="team image" loading="lazy" width="240" height="370" className="attachment-volia_240x370 size-volia_240x370 wp-post-image" decoding="async" /> :
                                                <img src="./img/team_1_1.jpg" alt="team image" loading="lazy" width="240" height="370" className="attachment-volia_240x370 size-volia_240x370 wp-post-image" decoding="async" />
                                            }
                                        </a>
                                        
                                            {/* <img loading="lazy" width="240" height="370" src="https://expert-themes.com/newwp/volia/wp-content/uploads/2022/04/speaker-1.jpg" class="attachment-volia_240x370 size-volia_240x370 wp-post-image" alt="" decoding="async" /></a> */}
                                        </figure>
                                        <span class="plus-icon fa fa-plus"></span>
                                        <div class="social-links">
                                            <a href={item.fb_url}><i class="fab fa-facebook"></i></a>
                                            <a href={item.lnkdin_url}><i class="fab fa-linkedin"></i></a>
                                            <a href={item.twitter_url}><i class="fab fa-twitter"></i></a>

                                        </div>

                                    </div>
                                    <div class="info-box text-center">
                                        
                                        <h4 class="name"><a href="#">{item.title}</a></h4>
                                        <span class="designation">{item.designation}</span>
                                    </div>
                                </div>
                            </div>
                        )) : ''}
                    </Slider>

                </div>
            </div>
        </section>
    )
}
export default Team
