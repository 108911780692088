import React, { useState } from 'react'
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, DropdownToggle, DropdownMenu, UncontrolledDropdown, Label } from 'reactstrap';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { checkData } from '../../ObjectExist';

const AddSpeaker = (props) => {
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState("");

    const { register: speakerInput, handleSubmit: speakerSubmit, formState: { errors }, reset } = useForm();
    const handleFileInput = (e) => {
        setImage(e.target.files[0])
    }
    const onAddSpeaker = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("admin_id", checkData(props.user) ? props.user.id : '');
        formData.append("title", data.title);
        formData.append("designation", data.designation);
        formData.append("long_desc", data.long_desc);
        formData.append("image", image);
        formData.append("status", data.status);
        formData.append("fb_url", data.fb_url);
        formData.append("lnkdin_url", data.lnkdin_url);
        formData.append("twitter_url", data.twitter_url);

        http.post(`speaker/add`, formData).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg, });
                props.setAddmodal(false); props.onApiInteg(); reset();
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Modal isOpen={props.addmodal} toggle={props.addmodal} scrollable={true} size="lg" className='addModal'>
                <ModalHeader toggle={props.toggleAdd}>Add New Speaker</ModalHeader>
                <ModalBody>
                    <Form onSubmit={speakerSubmit(onAddSpeaker)} >
                        <Row className="mx-0  addModal">
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="title">Enter title<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("title", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter title*" id="title" />
                                    {errors.title && <span className="text-danger d-block error">{errors.title.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="uploadLogo_add">Upload Image</Label>
                                    <input {...speakerInput("brandlogo", { required: false })} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                                    <span className="text-info d-block">400/200</span>
                                    {errors.brandlogo && <span className="text-danger d-block error">{errors.brandlogo.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="designation">Designation<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("designation", { required: 'Please Enter Designation' })} className="form-control-sm form-control" placeholder="Enter Designation*" id="designation" />
                                    {errors.designation && <span className="text-danger d-block error">{errors.designation.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fb_url">Facebook Link<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("fb_url", { required: 'Please Enter facebook link' })} className="form-control-sm form-control" placeholder="Enter Designation*" id="fb_url" />
                                    {errors.fb_url && <span className="text-danger d-block error">{errors.fb_url.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="lnkdin_url">Linkedin Link<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("lnkdin_url", { required: 'Please Enter Linkedin link' })} className="form-control-sm form-control" placeholder="Enter Designation*" id="lnkdin_url" />
                                    {errors.lnkdin_url && <span className="text-danger d-block error">{errors.lnkdin_url.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="twitter_url">Twitter Link<span className="text-danger">*</span></Label>
                                    <input {...speakerInput("twitter_url", { required: 'Please Enter Twitter Link' })} className="form-control-sm form-control" placeholder="Enter Designation*" id="twitter_url" />
                                    {errors.twitter_url && <span className="text-danger d-block error">{errors.twitter_url.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="long_desc">Description<span className="text-danger">*</span></Label>
                                    <textarea {...speakerInput("long_desc")} rows={10} className="form-control-sm form-control form-textarea" placeholder="Description*" id="long_desc" />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <FormGroup check className="pl-0">
                                        <Label>Status<span className="text-danger">*</span></Label>
                                        <div>
                                            <span className="form-check2 me-3">
                                                <input className="mx-2" {...speakerInput("status", { required: true })} type="radio" value="1" id="active_add" defaultChecked />
                                                <Label for="active_add">Active</Label>
                                            </span>
                                            <span className="form-check2">
                                                <input className="mx-2" {...speakerInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                <Label for="inactive_add">InActive</Label>
                                            </span>
                                        </div>
                                    </FormGroup>
                                </FormGroup>
                            </Col>

                            <Col sm={12} className="text-right">
                                <Button className="vs-btn border-0" color="primary">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddSpeaker