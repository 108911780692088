import React from 'react'
import Slider from "react-slick";

const EventsDetails = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <div class="breadcumb-wrapper background-image" style={{ backgroundImage: 'url(./img/breadcrumb-bg.jpg)' }}>
                <div class="container">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Event Details</h1>
                        <ul class="breadcumb-menu">
                            <li><a href="index.html">Home</a></li>
                            <li class="active">Event Details</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section class="space">
                <div class="container">
                    <div class="event-details">
                        <div class="event-slider-area">
                            <div class="event-img-slider vs-carousel slick-initialized slick-slider">
                                <Slider {...settings}>
                                    <div>
                                        <img src="./img/footer_bg_1.jpg" alt="icon" />
                                    </div>
                                    <div>
                                        <img src="./img/footer_bg_1.jpg" alt="icon" />
                                    </div>
                                    <div>
                                        <img src="./img/footer_bg_1.jpg" alt="icon" />
                                    </div>
                                </Slider>

                            </div>
                            {/* <div class="vs-icon-box d-none d-md-block">
                                <button data-slick-prev="#eventimgslide" class="icon-btn style-4 arrow-left"><i class="far fa-arrow-left"></i></button>
                                <button data-slick-next="#eventimgslide" class="icon-btn style-4 arrow-right"><i class="far fa-arrow-right"></i></button>
                            </div> */}
                        </div>
                        <div class="row gy-30 pt-40 align-items-center justify-content-between">
                            <div class="col-lg-7">
                                <div class="event-head">
                                    <div class="date">
                                        <span class="day">25</span>
                                        <span class="month">Aug</span>
                                    </div>
                                    <div class="title-info">
                                        <h2 class="event-title">Wonder Park Adventures </h2>
                                        <p class="event-info">
                                            <i class="fal fa-clock"></i>Time: 12:00pm - 5:00pm
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="btn-group">
                                    <a href="#" class="vs-btn">Save On Calendar</a>
                                    <a href="/register" class="vs-btn">Register</a>
                                </div>
                            </div>
                        </div>
                        <div class="event-content">
                            <p>Proactively enable reliable partnerships whereas process-centric potentialities. Authoritatively integrate covalent vortals whereas virtual e-markets. Competently empower customized customer service with standards compliant infrastructures. Energistically deliver integrated e-tailers after reliable technologies. Conveniently customize dynamic architectures with high-quality e-commerce. Efficiently productize just in time process improvements whereas transparent action items. Dynamically incubate multidisciplinary models vis-a-vis 2.0 ROI. Efficiently plagiarize fully researched experiences through bricks-and-clicks expertise. Seamlessly reconceptualize flexible content before viral web-readiness.</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="vs-info-box">
                                    <h3 class="vs-info-box_title">Event Info</h3>
                                    <ul class="vs-info-box_content">
                                        <li>
                                            <h5>Venue</h5>
                                            <span>258 Dancing Street, <br /> Avon MA 2322</span>
                                        </li>
                                        <li>
                                            <a class="fw-bold" href="#"><i class="fas fa-map-marker-alt"></i> View Location On Map</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="vs-info-box">
                                    <h3 class="vs-info-box_title">Details</h3>
                                    <ul class="vs-info-box_content">
                                        <li>
                                            <h5>Date:</h5>
                                            <span>Aug 22 - Aug 25</span>
                                        </li>
                                        <li>
                                            <h5>Time:</h5>
                                            <span>9:00 am - 9:00 pm</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="vs-info-box">
                                    <h3 class="vs-info-box_title">Organizer</h3>
                                    <ul class="vs-info-box_content">
                                        <li>
                                            <h4 class="fw-semibold">David Smith</h4>
                                            <h5>Email &amp; Phone:</h5>
                                            <span>david.smith@gmail.com <br /> +(259) 2156 2154</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div class="event-location mt-30">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.7252288471077!2d89.22872551898243!3d24.00547810054722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe9b97badc6151%3A0x30b048c9fb2129bc!2sAngfuzsoft!5e0!3m2!1sen!2sbd!4v1638077118980!5m2!1sen!2sbd" allowfullscreen="" loading="lazy"></iframe>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EventsDetails