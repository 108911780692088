import React, { useState } from 'react'
import { Collapse, Navbar, Col, NavbarToggler, NavbarBrand, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useLocation } from 'react-router';
import SideForm from './SideForm';
const NavMenu = (props) => {
    const urlPath = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [sideAnim, setSideAnim] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [aboutDrop, setAboutDrop] = useState(false);
    const [scroll, setScroll] = useState('false');
    const divRef = React.useRef();

    // const handleScroll = (e) => {
    //     console.log(`e`, e)
    //     const scrolledFromTop = divRef.current.scrollTop;
    //     console.log(`scrolledFromTop`, scrolledFromTop);
    //     setScroll(scrolledFromTop > 2);
    // };
    const [headerClassName, setHeaderClassName] = useState('main-header header-style-one');

    const handleScroll = (headerClassName) => {
        if (headerClassName !== 'main-header fixed-header' && window.pageYOffset >= 100) {
            setHeaderClassName('main-header fixed-header');
        } else if (headerClassName === 'main-header fixed-header' && window.pageYOffset < 100) {
            setHeaderClassName('main-header header-style-one');
        }
    }

    React.useEffect(() => {
        window.onscroll = () => handleScroll(headerClassName);
    }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
            <header className={headerClassName}>
                <div className="header-tops">
                    <div className="container">
                        <div className="inner-container">
                            <div className="top-left">
                                <ul className="social-icon-one">
                                    <li><a href={props.tempObj.facebook}><span className="fab fa-facebook"></span></a></li>
                                    <li><a href={props.tempObj.linkedin}><span className="fab fa-linkedin"></span></a></li>
                                    <li><a href={props.tempObj.twitter_url}><span className="fab fa-twitter"></span></a></li>
                                </ul>
                            </div>

                            <div className="top-right">
                                <ul className="contact-list-one">
                                    <li><i className="fa fa-location-arrow"></i> CIDCO Exhibition & Convention Centre, Navi Mumbai </li>                            
                                    <li><i className="fa fa-clock"></i>24 & 25 Nov 2023  </li>                            
                                    <li><i className="fa fa-envelope"></i> <a href="mailto:contact.gvs@iov.co.in">contact.gvs@iov.co.in</a> Mail to us</li>                        </ul>

                                <button className="nav-toggler" onClick={() => { setSideAnim(!sideAnim); }}><i className="fa fa-th"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-lower">
                    <div className="container">
                        <div className="main-box">
                            <div className="logo-box">
                                <div className="logo">
                                    <Link to='/' title="Volia"><img src={props.tempObj.path + '/' + props.tempObj.logo} alt="logo" /></Link></div>
                            </div>
                            <div className="nav-outer">
                                <nav className="main-menu navbar-expand-md">
                                    <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                        <Nav classNameName="ml-auto" navbar>
                                            <NavItem>
                                                <Link className={`nav-link ${urlPath.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
                                            </NavItem>
                                            <NavItem className="menu-item-has-children">
                                                <Link className={`nav-link ${(urlPath.pathname === '/page/about-us' || urlPath.pathname === '/page/about-iov'
                                                    || urlPath.pathname === '/page/about-iov'
                                                    || urlPath.pathname === '/page/about-ivc'
                                                    || urlPath.pathname === '/page/theme-of-53rd-ivc'
                                                    || urlPath.pathname === '/page/about-mumbai'
                                                    || urlPath.pathname === '/page/travel-desk'
                                                    || urlPath.pathname === '/page/committees-of-gvs'
                                                    || urlPath.pathname === '/page/conference-advisory-group-of-iov') ? 'active' : ''}`}  to="/page/about-us">About </Link>
                                                <ul className="sub-menu">
                                                    <li><Link className={`nav-link nav-sub`} to="/page/about-iov">About IOV</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/about-ivc">About IVC</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/theme-of-53rd-ivc">Theme of GVS</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/conference-advisory-group-of-iov">Conference Advisory Group of IOV</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/about-mumbai">About Mumbai</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/travel-desk">Travel Desk</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/committees-of-gvs">Committees of GVS</Link></li>

                                                </ul>
                                            </NavItem>
                                            <NavItem >
                                                <Link className={`nav-link ${urlPath.pathname === '/events' ? 'active' : ''}`} to="/events">Event</Link>

                                            </NavItem>
                                            <NavItem>
                                                <Link className={`nav-link ${urlPath.pathname === '/event/gallery' ? 'active' : ''}`} to="/event/gallery">Gallery</Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link className={`nav-link ${urlPath.pathname === '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link className={`nav-link ${urlPath.pathname === '/page/partners' ? 'active' : ''}`} to="/page/partners">Partners</Link>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </nav>

                                <div className="outer-box clearfix">
                                    {/* <div className="search-box-btn search-btn search-box-outer"><span className="icon fa fa-search"></span></div> */}

                                    <div className="btn-box">
                                        <Link to='/register' className="theme-btn btn-style-one"><span className="btn-title"><i className="flaticon-chair"></i> Download ID Card</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header">
                    <div className="container">
                        <div className="main-box">
                            <div className="logo-box">
                                <div className="logo">
                                    <Link to='/'><img src={props.tempObj.path + '/' + props.tempObj.logo} alt="logo" /></Link>
                                </div>
                                <div className="upper-right">
                                    {/* <div className="search-box">
                                        <div className="search-box" onClick={() => setMobileMenu(true)}>
                                            <i className="fa fa-bars"></i>
                                        </div>
                                    </div> */}
                                    <div className="search-box" onClick={() => setMobileMenu(true)}>
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>

                            <nav className="main-menu navbar-expand-md">
                            </nav>

                            <nav className="main-menu navbar-expand-md">
                                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                    <Nav classNameName="ml-auto" navbar>
                                        <NavItem>
                                            <Link className={`nav-link ${urlPath.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
                                        </NavItem>
                                        <NavItem className="menu-item-has-children">
                                            <Link className={`nav-link ${(urlPath.pathname === '/page/about-us' || urlPath.pathname === '/page/about-iov'
                                                || urlPath.pathname === '/page/about-iov'
                                                || urlPath.pathname === '/page/about-ivc'
                                                || urlPath.pathname === '/page/theme-of-53rd-ivc'
                                                || urlPath.pathname === '/page/about-mumbai'
                                                || urlPath.pathname === '/page/travel-desk'
                                                || urlPath.pathname === '/page/committees-of-gvs'
                                                || urlPath.pathname === '/page/conference-advisory-group-of-iov') ? 'active' : ''}`} to="/page/about-us">About</Link>
                                            <ul className="sub-menu">
                                                <li><Link className={`nav-link nav-sub`} to="/page/about-iov">About IOV</Link></li>
                                                <li><Link className={`nav-link nav-sub`} to="/page/about-ivc">About IVC</Link></li>
                                                <li><Link className={`nav-link nav-sub`} to="/page/theme-of-53rd-ivc">Theme of GVS</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/conference-advisory-group-of-iov">Conference Advisory Group of IOV</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/about-mumbai">About Mumbai</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/travel-desk">Travel Desk</Link></li>
                                                    <li><Link className={`nav-link nav-sub`} to="/page/committees-of-gvs">Committees of GVS</Link></li>
                                            </ul>
                                        </NavItem>
                                        <NavItem >
                                            <Link className={`nav-link ${urlPath.pathname === '/events' ? 'active' : ''}`} to="/events">Event</Link>

                                        </NavItem>
                                        <NavItem>
                                            <Link className={`nav-link ${urlPath.pathname === '/event/gallery' ? 'active' : ''}`} to="/event/gallery">Gallery</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={`nav-link ${urlPath.pathname === '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link className={`nav-link ${urlPath.pathname === '/page/partners' ? 'active' : ''}`} to="/page/partners">Partners</Link>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </nav>

                            <div className="outer-box clearfix">
                                <div className="search-box-btn search-btn search-box-outer"><span className="icon fa fa-search"></span></div>

                                <div className="btn-box">
                                    <a href="#" className="theme-btn btn-style-one"><span className="btn-title"><i className="flaticon-chair"></i> Register Now</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-header">
                    <div className="logo"><Link to='/' title="Volia"><img src={props.tempObj.path + '/' + props.tempObj.logo} alt="logo" /></Link></div>
                    <div className="nav-outer clearfix">
                        <div className="outer-box">
                            <div className="search-box" onClick={() => setMobileMenu(true)}>
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mobile-menu ${mobileMenu}`}>
                    <div className="menu-backdrop"></div>

                    <nav className={`menu-box ${mobileMenu}`}>

                        <div className="upper-box">
                            <div className="nav-logo"><Link to='/' title="Volia"><img src={props.tempObj.path + '/' + props.tempObj.logo} alt="logo" /></Link></div>
                            <div className="close-btn" onClick={() => setMobileMenu(false)}><i className="fa fa-times"></i></div>
                        </div>

                        <ul className={`navigation clearfix ${mobileMenu}`}>
                            <Nav classNameName="ml-auto" navbar>
                                <NavItem>
                                    <Link className={`nav-link ${urlPath.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
                                </NavItem>
                                <NavItem className="menu-item-has-children">
                                    <Link className={`nav-link ${(urlPath.pathname === '/page/about-us' || urlPath.pathname === '/page/about-iov'
                                        || urlPath.pathname === '/page/about-iov'
                                        || urlPath.pathname === '/page/about-ivc'
                                        || urlPath.pathname === '/page/theme-of-53rd-ivc'
                                        || urlPath.pathname === '/page/about-mumbai'
                                        || urlPath.pathname === '/page/conference-advisory-group-of-iov') ? 'active' : ''}`} to="/page/about-us">About</Link>
                                    <ul className="sub-menu">
                                        <li><Link className={`nav-link nav-sub`} to="/page/about-iov">About IOV</Link></li>
                                        <li><Link className={`nav-link nav-sub`} to="/page/about-ivc">About IVC</Link></li>
                                        <li><Link className={`nav-link nav-sub`} to="/page/theme-of-53rd-ivc">Theme of 53rd IVC</Link></li>
                                        <li><Link className={`nav-link nav-sub`} to="/page/conference-advisory-group-of-iov">Conference Advisory Group of IOV</Link></li>
                                        <li><Link className={`nav-link nav-sub`} to="/page/about-mumbai">About Mumbai</Link></li>
                                    </ul>
                                </NavItem>
                                <NavItem >
                                    <Link className={`nav-link ${urlPath.pathname === '/events' ? 'active' : ''}`} to="/events">Event</Link>

                                </NavItem>
                                <NavItem>
                                    <Link className={`nav-link ${urlPath.pathname === '/event/gallery' ? 'active' : ''}`} to="/event/gallery">Gallery</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className={`nav-link ${urlPath.pathname === '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className={`nav-link ${urlPath.pathname === '/page/partners' ? 'active' : ''}`} to="/page/partners">Partners</Link>
                                </NavItem>
                            </Nav>
                        </ul>

                        <ul className="contact-list-one">
                            <li><i className="fa fa-location-arrow"></i> CIDCO Exhibition & Convention Centre, Navi Mumbai <strong>Address</strong></li>
                            <li><i className="fa fa-clock"></i>Friday - Saturday 9am - 6pm <strong>Event Time</strong></li>
                            <li><i className="fa fa-envelope"></i> <a href="mailto:contact.gvs@iov.co.in">contact.gvs@iov.co.in</a> <strong>Mail to us</strong></li>                </ul>

                        <ul className="social-links">
                            <li><a href="https://www.facebook.com/"><span className="fab fa-facebook"></span></a></li>
                            <li><a href="https://www.linkedin.com/"><span className="fab fa-linkedin"></span></a></li>
                            <li><a href="https://www.skype.com/"><span className="fab fa-skype"></span></a></li>
                            <li><a href="https://www.twitter.com/"><span className="fab fa-twitter"></span></a></li>
                        </ul>
                    </nav>
                </div>
            </header>
            {sideAnim && <SideForm setSideAnim={setSideAnim} />}
        </>
    )
}

export default NavMenu