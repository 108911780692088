import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { checkData } from '../../ObjectExist';
import { Link } from 'react-router-dom';
import Preloader from './Preloader';
import { useTimer } from 'react-timer-hook';

// function MyTimer({ expiryTimestamp }) {
//     const {
//         seconds,
//         minutes,
//         hours,
//         days,
//         isRunning,
//         start,
//         pause,
//         resume,
//         restart,
//     } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


//     return (
//         <div className='time-counter-one'>
//             {/* <h1>react-timer-hook </h1> */}
//             {/* <p>Timer Demo</p> */}
//             <div className="time-countdown">
//                 <div className="counter-column">
//                     <span className="count">{days}</span>D</div>
//                 <div className="counter-column"><span className="count">{hours}</span>H</div>
//                 <div className="counter-column"><span className="count">{minutes}</span>M</div>
//                 <div className="counter-column"><span className="count">{seconds}</span>S</div>
//             </div>
//         </div>
//     );
// }
const TopSlider = () => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`banner/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`pages/view`, data).then((res) => {
            console.log('res', res)
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }, []);
    let secondBetweenTwoDate = Math.abs((new Date("2023-11-24").getTime() - new Date().getTime()) / 1000);
    // console.log('secondBetweenTwoDate', secondBetweenTwoDate, 'date', new Date("2022-12-16"), 'days', secondBetweenTwoDate/86400)
    const time = new Date();
    time.setSeconds(time.getSeconds() + secondBetweenTwoDate);

    return (
        <div>
            {loader && <Preloader />}
            <Slider {...settings}>
                {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (
                    <div>
                        <img src={`${apiData.path}/${item.image}`} className='desktop__banner' alt='"Guwahati","IOV", "IVC", "indian valuers congress", "Valuers", "institution of valuers", "valuation"' />
                        <img src='./img/mobile__banner.jpg' className='mobile__banner' alt='"Guwahati","IOV", "IVC", "indian valuers congress", "Valuers", "institution of valuers", "valuation"' />
                        <div className="content-slider">
                            <img src='./img/IBBI.jpeg' className='banner_left_img' alt='' />
                            <img src='./img/IOV-logo.jpg' className='banner_right_img' alt='' />

                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                                        <div className="righ-side-slide">
                                            <p className="slider-title">Global Valuation <span className='newText'>Summit</span></p>
                                            <div className="mapcalender">
                                                <div>
                                                    <span><i className="fa fa-map-marker"></i></span>
                                                    <span className='ml-3'>CIDCO Exhibition & Convention Centre</span>
                                                </div>
                                                <div className='ml-3'>
                                                    <span><i class="fa fa-calendar"></i></span>
                                                    <span className='ml-3'>November 24 To 25, 2023</span>
                                                </div>
                                            </div>
                                            <div className="btn-box d-none">
                                                <Link to='/register' className="theme-btn btn-style-one bnr-btn">
                                                    <span className="btn-title"><i className="flaticon-chair"></i> Register Now</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <MyTimer expiryTimestamp={time} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ''}
            </Slider>

        </div>

          

    )
}

export default TopSlider