import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { checkUndeNullBlank } from '../../ObjectExist';



const Why = () => {

    const [viewModal, setViewModal] = useState(false);
    const [viewImage, setViewImage] = useState('');
    const toggleView = (name) => {
        setViewModal(!viewModal);
        if (checkUndeNullBlank(name)) {
            setViewImage(name);
        }

    }

    return (
        <section className="pricing-section">
            <div className="anim-icons full-width">
                <span className="icon icon-circle-2"></span>
            </div>
            <div className="container position-relative">
                <div className="sec-title text-center">
                    {/* <span className="sub-title">Get Ticket</span> */}
                    <h2>WHY TO<span className='newText'>ATTEND GVS?</span></h2>
                    <span className="divider"></span>
                </div>

                <div className="row">
                    <div className="pricing-block  col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms">
                        <div className="inner-box">
                            {/* <span className="title">Global Valuation Summit</span> */}
                            <h3></h3>
                            {/* <div className="price">GVS</div> */}
                            <ul className="features">
                                <li>Clarity on importance & purpose of Valuation and related relevant legal provisions.</li>
                                <li>Value addition</li>
                                <li>Face-to-face exposure promoting</li>
                                <li>Knowledge sharing</li>
                                <li>Enriching experience</li>
                                {/* <li>T-Shart</li> */}
                            </ul>
                            <Link to="/register" className="theme-btn btn-style-one"><span className="btn-title">Register</span></Link>
                        </div>
                    </div>
                    <div className="pricing-block tagged col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms">
                        <div className="inner-box">
                            {/* <span className="title">Global Valuation Summit</span> */}
                            <h3></h3>
                            {/* <div className="price">GVS</div> */}
                            <ul className="features">
                                <li>Renowned Speakers with unbound experience and expertise in their respective fields.</li>
                                <li>Updation on the developments</li>
                                <li>Enhancing skills</li>
                                <li>Broadening perspective</li>
                                <li>Networking</li>
                                {/* <li>T-Shart</li> */}
                            </ul>
                            <Link to="/register" className="theme-btn btn-style-one newBtnColor"><span className="btn-title">Register</span></Link>
                        </div>
                    </div>
                    <div className="pricing-block  col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms">
                        <div className="inner-box">
                            {/* <span className="title">Global Valuation Summit</span> */}
                            <h3></h3>
                            {/* <div className="price">GVS</div> */}
                            <ul className="features">
                                <li>Expanding referral base</li>
                                <li>Brand building</li>
                                <li>Insight on Valuation in Digital Age</li>
                                <li>CEP Credit hours for Registered Valuers</li>
                                <li>Active Valuer credits for IOV Members</li>
                                {/* <li>T-Shart</li> */}
                            </ul>
                            <Link to="/register" className="theme-btn btn-style-one"><span className="btn-title">Register</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Why
