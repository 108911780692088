import React from 'react'
import AboutHome from './AboutHome';
import Direction from './Direction';
import Features from './Features';
import Partner from './Partner';
import Updates from './Updates';
import Sliderr from './Sliderr';
import Posts from './Posts';
import Team from './Team';
import TopSlider from './TopSlider';
import Why from './Why';
import CustomModal from './CustomModal';

const Home = () => {
    return <div className="element">
        <TopSlider />
        <AboutHome/>
        <Team />
        <Why />
        <Partner />
        <Updates />
        <Posts />
        {/* <Features /> */}
        {/* <Sliderr /> */}
        <Direction />
        <CustomModal />
    </div>;
};

export default Home;