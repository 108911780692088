import React, { useState, useEffect } from 'react'
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank  } from '../../ObjectExist';
import { Link } from 'react-router-dom';


const Footer = (props) => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`pages/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);
    //console.log('props', props);
    return (
        <>
            <section className='d-none' data-pos-for=".footer-wrapper" data-sec-pos="bottom-half" style={{ marginBottom: '-122px' }}>
                <div className="container">
                    <div className="newsletter-wrap background-image" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/img/newsletter_bg_1.jpg)` }}>
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6 mb-40 mb-lg-0">
                                <span className="sub-title2">
                                    Don’t Miss Our Updates!
                                    <img className="title-shape" src={`${process.env.REACT_APP_BASE_URL}/img/title_shape.png`} alt="title shape" />
                                </span>
                                <h2 className="sec-title">Get Subscribe Now!</h2>
                            </div>
                            <div className="col-lg-6">
                                <form action="#" className="newsletter-form style2">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter Your Email" />
                                        <button type="submit" className="vs-btn">Subscribe Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="shape"></div>
                    </div>
                </div>
            </section>
            <footer className="footer-wrapper footer-layout4 background-image" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/img/footer_bg_1.jpg)` }} >
                <div className="widget-area">
                    <div className="container">
                        <div className="row justify-content-between">

                            <div className="col-xl-3 col-md-6">
                                <div className="widget footer-widget">
                                    <div className="vs-widget-about text-secondary">
                                        <div className="about-logo text-secondary">
                                        {checkUndeNullBlank(props.tempObj.footer_logo) && 
                                            
                                                <img src={props.tempObj.path + '/' + props.tempObj.footer_logo} alt="IOV" className="footer-logo" />
                                            
                                        }
                                        </div>
                                        <p className="about-text text-secondary">{checkData(props.tempObj) && props.tempObj.footer_about}</p>
                                        <div className="vs-social">
                                            <a href={props.tempObj.facebook} target="_blank"><i className="fab fa-facebook-f text-secondary"></i></a>
                                            <a href={props.tempObj.twitter_url} target="_blank"><i className="fab fa-twitter text-secondary"></i></a>
                                            {/* <a href={props.tempObj.footer_about} target="_blank"><i className="fab fa-instagram"></i></a> */}
                                            <a href={props.tempObj.linkedin} target="_blank"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="widget footer-widget widget_nav_menu">
                                    <h4 className="widget_title text-secondary">Our Services</h4>
                                    <ul className="menu text-secondary">
                                        <li><a className='text-secondary' href=" https://www.institutionofvaluers.net/council" target="_blank"><i class="fas fa-chevron-right"></i>Council Member</a></li>
                                        <li><a className='text-secondary' href=" https://www.institutionofvaluers.net/committees_list" target="_blank"><i class="fas fa-chevron-right"></i>Committees of IOV</a></li>
                                        <li><a className='text-secondary' href="https://www.institutionofvaluers.net/news" target="_blank"><i class="fas fa-chevron-right"></i>News</a></li>
                                        <li><a className='text-secondary' href="https://www.institutionofvaluers.net/contact" target="_blank"><i class="fas fa-chevron-right"></i>Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="widget footer-widget widget_nav_menu">
                                    <h4 className="widget_title text-secondary">Quick links</h4>
                                    <ul className="menu text-secondary">
                                    {checkData(apiData) && (apiData) ? apiData.map((item, index) => (
                                        <>
                                        { item.id == 2 || item.id ==3 || item.id == 4 || item.id == 8 || item.id == 9 ?
                                          <li><Link  to={`/page/${item.slug}`} className='text-secondary'><i class="fas fa-chevron-right"></i>{item.title}</Link></li>
                                          :''
                                        }
                                        </>
                                       )) : ''}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="widget footer-widget d-none">
                                    <h4 className="widget_title ">Instagram Posts</h4>
                                    <div className="widget-gallery">
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                        <div className="gallery-thumb">
                                            <img src="./img/gal-1-1.jpg" alt="Gallery Image" />
                                            <a href="./img/gal-1-1.jpg" className="icon-thumb popup-image"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">
                        <p className="copyright font-weight-normal">Copyright © 2023 <strong className='font-weight-bold'> Institution of Valuers </strong>. All Rights Reserved By <a target="_blank" href="https://www.institutionofvaluers.net/">IOV</a></p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer