import React, { useState } from 'react'
import { Form, Spinner, FormGroup, Label, Card, CardBody, Badge, TabContent, TabPane, } from 'reactstrap'
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { StateList } from '../../StateList';
import classnames from 'classnames';
import { makePaymentpayu } from './Payu';
import { Link } from 'react-router-dom';

const ResponseP = (props) => {
    let resMessage = (new URLSearchParams(window.location.search)).get("msg");
    let resStatus = (new URLSearchParams(window.location.search)).get("status");
    const [loader, setLoader] = useState(false);
    const [delegate, setDelegate] = useState(false);
    const [delegateMore, setDelegateMore] = useState(false);
    const [activeTab, setActiveTab] = useState('member');
    const [category, setCategory] = useState('');

    console.log('resMessage', resMessage, 'resStatus', resStatus);

    return (
        <div>
            {loader && <div className="formLoader"><Spinner /></div>}
            <div className="breadcumb-wrapper" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/img/breadcrumb-bg.jpg)` }}>
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Response</h1>
                        <ul className="breadcumb-menu">
                            <li><a href="./">Home</a></li>
                            <li className="active">Response</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="space">
                <div className="container text-center">
                    {resStatus === 'success' || resStatus === 'captured ' ?
                        <h1 className="text-success success-title">Success</h1>
                        :
                        <h1 className="error-title">Error</h1>

                    }
                    {resStatus === 'success' || resStatus === 'captured ' ?
                        <h2>Registration Successfull</h2>
                        :
                        <h2>{resMessage}</h2>
                    }
                    {/* <span className="d-block mb-30">Sorry, but the page you are looking for does not existing</span> */}
                    <Link to="/" className="vs-btn"><i className="mr-5 fas fa-home-lg"></i> Back to Home</Link>
                </div>
            </div>
        </div>
    )
}

export default ResponseP