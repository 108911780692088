export function checkData(val,var1){
    if(var1 == 1){
    console.log('a',JSON.stringify(val));
    console.log('var',var1);
    }
    return (val !== undefined && val !== null && val !== '' && Object.entries(val).length >0 ) ? true : false; 
}
export function checkArrayData(val){
    return val.length > 0 ? true : false; 
}
export function checkUndeNullBlank(val){
    return (val !== undefined && val !== null && val !== '') ? true : false; 
}