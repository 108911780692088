import React from 'react'
import { useTimer } from 'react-timer-hook';
import { Link } from 'react-router-dom';

function MyTimer({ expiryTimestamp }) {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


    return (
        <div style={{ textAlign: 'center', marginBottom: '1rem', }}>
            {/* <h1>react-timer-hook </h1> */}
            {/* <p>Timer Demo</p> */}
            <div className="bg-auto-repeat background-image" data-pos-for=".countdown-section" data-sec-pos="bottom-half">
                <div className="container z-index-common">
                    <ul className="event-counter style5 counter-list" data-offer-date="03/03/2023">
                        <li>
                            <div className="day count-number">{days}</div>
                            <span className="count-name">Days</span>
                        </li>
                        <li>
                            <div className="hour count-number">{hours}</div>
                            <span className="count-name">Hours</span>
                        </li>
                        <li>
                            <div className="minute count-number">{minutes}</div>
                            <span className="count-name">Minutes</span>
                        </li>
                        <li>
                            <div className="seconds count-number">{seconds}</div>
                            <span className="count-name">Seconds</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}


const AboutHome = () => {
    let secondBetweenTwoDate = Math.abs((new Date("2022-12-16").getTime() - new Date().getTime()) / 1000);
    // console.log('secondBetweenTwoDate', secondBetweenTwoDate, 'date', new Date("2022-12-16"), 'days', secondBetweenTwoDate/86400)
    const time = new Date();
    time.setSeconds(time.getSeconds() + secondBetweenTwoDate);
    return (
        <section class="about-section">
            {/* <MyTimer expiryTimestamp={time} />
            <div className="time-counter-one">
                <div class="time-countdown">
                    <div class="counter-column">
                        <span class="count">194</span>D</div>
                    <div class="counter-column"><span class="count">01</span>H</div>
                    <div class="counter-column"><span class="count">16</span>M</div>
                    <div class="counter-column"><span class="count">37</span>S</div>
                </div>
            </div> */}
            <div class="container">
                <div class="row">
                    <div class="image-column col-lg-6 col-md-12 col-sm-12">
                        <div class="about-image-wrapper">
                            <figure class="image-3 wow zoomIn animated" data-wow-delay="900ms"><img decoding="async" src="./img/home_second.jpg" alt="Awesome Image" /></figure>
                            <figure class="image-2 wow zoomIn animated" data-wow-delay="600ms"><img decoding="async" src="./img/home_first.jpg" alt="Awesome Image" /></figure>
                            <figure class="image-1 wow zoomIn animated" data-wow-delay="300ms"><img decoding="async" src="./img/vector.png" alt="Awesome Image" /></figure>
                            <a href="https://youtu.be/omRSM3y-luA" class="lightbox-image play-btn wow zoomIn animated" data-wow-delay="1200ms"><span class="icon fa fa-play"></span></a>
                        </div>
                    </div>
                    <div class="content-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column">
                            <div class="sec-title">
                                <span class="sub-title">WELCOME TO GVS</span>
                                <h2 className='newText'>More About Us</h2>
                                <span class="divider"></span>
                            </div>
                            <p>This year, IOV in association with IBBI and IOV RVF is holding the 2nd "GLOBAL VALUATION SUMMIT" (GVS) on 24th and 25th November 2023 at Mumbai. GVS presents an incredible opportunity to bring together global experts and discuss crucial topics from a global perspective, thereby providing wider exposure.</p>
                            {/* <p> IVC provides a crucial platform for disseminating know-how on Valuation and showcasing the profound impact on the current practices of the valuation profession through interaction amongst the valuers and stakeholders beyond boundaries as well */}
                            {/* </p> */}
                            <div class="btn-box">
                                <Link to="/register" class="theme-btn btn-style-one"><span class="btn-title">Register</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default AboutHome;
