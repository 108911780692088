import React, { useState, useEffect } from 'react'
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import Slider from "react-slick";

const Updates = () => {
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);

    const onApiInteg = () => {
        let data = { admin_id: 1,"category":'International' }
        setLoader(true);
        http.post(`/partner/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: checkData(apiData) && checkData(apiData.data) && Object.keys(apiData.data).length > 2 ? 3 : checkData(apiData) && checkData(apiData.data) ? Object.keys(apiData.data).length : '',
        // slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };
    return (
        <section class="clients-section ">
            <div class="container">
                <div class="sec-title text-center position-relative">
                    <h2>Our<span className='newText'>International Partners</span></h2>
                    {/* <span class="sub-title">We have dedicated partners and each individual are very special for us</span> */}
                    <span class="divider"></span>

                </div>
                <div class="row">
                    {/* <div class="title-column col-xl-3 col-lg-4 col-md-12">
                        <div class="part-title">
                            <span class="sub-title">Clients</span>
                            <h2>Our Official <br />Partners</h2>
                            <span class="divider"></span>
                            <div class="text">We have dedicated partners and each individual are very special for us.</div>
                            <a href="#" class="theme-btn btn-style-one mt-4 d-none"><span class="btn-title">Read More</span></a>
                        </div>
                    </div> */}

                    <div class="title-column col-xl-12 col-lg-12 col-md-12">
                        <div class="sponsors-outer">
                            <div class="row">
                                <Slider {...settings} className="12">
                                    {checkData(apiData) && checkData(apiData.data) ? apiData.data.map((item, index) => (
                                        <div class="client-block col-lg-4 col-md-6 col-sm-12 wow fadeInRight animated">
                                            <figure class="image-box">
                                                <a href={item.website_link} tabindex="-1" target="_blank">
                                                    {checkUndeNullBlank(item.image) ?
                                                        <img decoding="async" src={`${apiData.path}/${item.image}`} alt="team image" /> :
                                                        <img decoding="async" src="./img/team_1_1.jpg" alt="team image" />
                                                    }
                                                    {/* <img decoding="async" src="https://expert-themes.com/newwp/volia/wp-content/uploads/2022/04/1-1.jpg" alt="Awesome Image" /> */}
                                                </a></figure>
                                        </div>
                                    )) : ''}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Updates
