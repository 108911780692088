import React, { useState, useEffect } from 'react';
import http from '../../CommonAxios';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import {  Modal } from 'reactstrap';


const CustomModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiData, setApiData] = useState({});
  const [timeOut, setTimeout] = useState('');
  const [loader, setLoader] = useState(false);

  const onApiInteg = () => {
      let data = { admin_id: 1 }
      setLoader(true);
      http.post(`/popup/front/list`, data).then((res) => {
          setLoader(false);
          if (res.data.s === 1) {
              setApiData(res.data)
              setTimeout(res.data[0].seconds)
              console.log('res.data', res.data)
          }
      }).catch(function (err) {
          setLoader(false);
      });
  };
  useEffect(() => {
      onApiInteg();
      setTimeout(()=>{
        handleOpenModal()
      }, timeOut)
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* <button className='theme-btn btn-style-one' onClick={handleOpenModal}>Open Modal</button> */}
      {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (
        <Modal isOpen={isModalOpen}>
          <button type="button" class="btn-close" onClick={handleCloseModal} aria-label="Close"></button> 
          <a href={item.link}>
            <img src={`${apiData.path}/${item.image}`} alt="meeting" />
          </a>
        </Modal>

      )) : ''}
      
    </div>
  );
};

export default CustomModal;
