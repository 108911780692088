import React, { useState, useEffect } from 'react'
import './App.css';
import { Route, Routes, Navigate } from "react-router-dom";
import NavMenu from './components/frontend/NavMenu';
import Home from './components/frontend/Home';
import About from './components/frontend/About';
import Footer from './components/frontend/Footer';
import Sidebar from './components/backend/Sidebar';
import Header from './components/backend/Header';
import { useLocation } from 'react-router';
import Login from './components/backend/Login';
import Event from './components/backend/Event';
import Gallery from './components/backend/Gallery';
import Banner from './components/backend/Banner';
import CMS from './components/backend/CMS';
import SiteSetting from './components/backend/SiteSetting';
import { encryptStorageLogin } from './EncStorage';
import { checkData, checkUndeNullBlank } from './ObjectExist';
import Contact from './components/frontend/Contact';
import EventList from './components/frontend/EventList';
import EventsDetails from './components/backend/EventsDetails';
import ContactList from './components/backend/ContactList';
import Speakers from './components/backend/Speakers';
import Popup from './components/backend/Popup';
import Partners from './components/backend/Partners';
import Dashboard from './components/backend/Dashboard';
import CmsPages from './components/frontend/CmsPages';
import http from './CommonAxios'
import { Helmet } from "react-helmet";
import Register from './components/frontend/Register';
import EventItem from './components/backend/EventItem';
import EventGallery from './components/frontend/EventGallery';
import ResponseP from './components/frontend/ResponseP';
import RegistartionList from './components/backend/RegistrationList';
import ActivityList from './components/backend/ActivityList';
import AllAdmin from './components/backend/AllAdmin';
//import TawkTo from 'tawkto-react'


function App() {
  const urlPath = useLocation();
  const userdec = encryptStorageLogin.getItemFromPattern('userenc');
  const user = userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && checkUndeNullBlank(userdec.userenc.data) ? userdec.userenc.data : userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && userdec.userenc;
  const [apiData, setApiData] = useState({});
  const [permissions, setPermissions] = useState({});

  const [loader, setLoader] = useState(false);


  const onApiInteg = () => {
    let data = { admin_id: 1 }
    setLoader(true);
    http.post(`admin/settings-view`, data).then((res) => {
      setLoader(false);
      if (res.data.s === 1) {
        setApiData(res.data)
        // reset(res.data.data)
      }
    }).catch(function (err) {
      setLoader(false);
    });
  };

  const permAPI = () => {
    if (checkData(user) && checkUndeNullBlank(user[0].id)) {
      let data = { id: checkUndeNullBlank(user[0].id) ? user[0].id : '', admin_id: 1 }
      setLoader(true);
      http.post(`admin/view`, data).then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          if (checkData(res.data.data)) {
            setPermissions(JSON.parse(res.data.data[0].permissions))
          }
        }
      }).catch(function (err) {
        setLoader(false);
      });
    }
  }

  useEffect(() => {
    onApiInteg();
  //   var adminURL=['/adminlogin','/users','/sitesetting','/speakers','/dashboard','/event','/activityList','/registrationList','/partners','/speakers','/popup'];
  //  if(urlPath.pathname!=""){
  //   if(!adminURL.includes(urlPath.pathname)){
  //    // var tawk = new TawkTo("6548788aa84dd54dc488d83f", "1hehhdoa7")
  //   }
  // }
      

  }, []);
  let tempObj = {}
  if (checkData(apiData) && checkData(apiData.data)) {
    tempObj.path = apiData.path
    apiData.data.map((item, index) => {
      if (item.option_key === 'address') {
        tempObj.address = item.option_value
      } else if (item.option_key === 'twitter_url') {
        tempObj.twitter_url = item.option_value
      } else if (item.option_key === 'linkedin') {
        tempObj.linkedin = item.option_value
      } else if (item.option_key === 'facebook') {
        tempObj.facebook = item.option_value
      } else if (item.option_key === 'logo') {
        tempObj.logo = item.option_value
      } else if (item.option_key === 'favicon') {
        tempObj.favicon = item.option_value
      }else if (item.option_key === 'footer_logo') {
        tempObj.footer_logo = item.option_value
    } else if (item.option_key === 'top_right_logo') {
        tempObj.top_right_logo = item.option_value
    } else if (item.option_key === 'meta_title') {
        tempObj.meta_title = item.option_value
      } else if (item.option_key === 'meta_keywords') {
        tempObj.meta_keywords = item.option_value
      } else if (item.option_key === 'meta_description') {
        tempObj.meta_description = item.option_value
      }else if (item.option_key === 'footer_about') {
        tempObj.footer_about = item.option_value
      }else if (item.option_key === 'phone') {
        tempObj.phone = item.option_value
      }else if (item.option_key === 'email_for_contact') {
        tempObj.email_for_contact = item.option_value
      }else if (item.option_key === 'company_name') {
        tempObj.company_name = item.option_value
      }
      return true
    });
  }
  //console.log('tempObj', tempObj)
  if(urlPath.pathname === "/page/about-us"){
    tempObj.meta_keywords="Global Valuation Summit 2023";
    tempObj.meta_title="About Global Valuation Summit 2023: Our Vision and Impact";
    tempObj.meta_description="GVS offers a remarkable chance to unite worldwide experts, engaging in vital discussions from a global standpoint, thus offering extensive visibility.";
  }else if(urlPath.pathname === '/page/about-iov'){
  
    tempObj.meta_keywords="Global Valuation Summit";
    tempObj.meta_title="Discover the Vision Behind IOV: Global Valuation Summit";
    tempObj.meta_description="Explore IOV & its mission at the Global Valuation Summit. Join us for insights,  formulation of ideas, spreading knowledge, & education. Visit to know more!";
  
  }else if(urlPath.pathname === '/page/about-ivc'){
    tempObj.meta_keywords="";
    tempObj.meta_title="55 Years of Successful Valuation Congresses Across India- IVC";
    tempObj.meta_description="For 55 years, we've organized successful Valuation Congresses across India, inaugurated by Governors, Ministers, and attended by government officials and valuers. Read more!";
    
  }else if(urlPath.pathname === '/page/theme-of-53rd-ivc'){
    tempObj.meta_keywords="World Summits";
    tempObj.meta_title="Global Valuation Trends: Theme of GVS - Mapping Momentum";
    tempObj.meta_description="Join the IVC World Summits, focusing on 'Mapping the Momentum & Global Outreach of Valuation Profession'. Explore how we shaping up the Valuation Ecosystem, its transformation & development.";
    
  }else if(urlPath.pathname === '/page/conference-advisory-group-of-iov'){
    tempObj.meta_keywords="Market valuation";
    tempObj.meta_title="IOV's Advisory Group for Conferences";
    tempObj.meta_description="Discover how IOV's Conference Advisory Group members shape market valuation. Join us to explore innovation and expertise in the field.";
    
  }else if(urlPath.pathname === '/page/about-mumbai'){
    tempObj.meta_keywords="Global Valuation Summit 2023";
    tempObj.meta_title="Discover Mumbai's Essence: - Global Valuation Summit";
    tempObj.meta_description="Explore the vibrant of Mumbai's culture, history, and significance. Learn more about Mumbai's essence at the Global Valuation Summit's 2023 About Mumbai page.";
    
  }else if(urlPath.pathname === '/events'){
    tempObj.meta_keywords="Market valuation, Global Valuation Summit 2023";
    tempObj.meta_title="Premier Valuation Events: Global Valuation Summit";
    tempObj.meta_description="Join top market valuation experts worldwide. Explore insightful events, workshops, and seminars focused on valuation at Global Valuation Summit's 2023 Events page.";
    
  }else if(urlPath.pathname === '/event/gallery'){
    tempObj.meta_keywords="Global Business summit";
    tempObj.meta_title="Event Gallery: Capturing Valuable Moments - Global Business summit";
    tempObj.meta_description="Relive our cherished valuation event through captivating photos and videos. Explore the Event Gallery at Global Business Summit for a glimpse into valuable moments.";
    
  }else if(urlPath.pathname === '/contact'){
    tempObj.meta_keywords="GVS";
    tempObj.meta_title="Contact Us: Reach GVS Team";
    tempObj.meta_description="Have questions or inquiries? Get in touch with us. Reach out to the Global Valuation Summit team through our contact page for assistance and information.";
    
  }else if(urlPath.pathname === '/page/partners'){
    tempObj.meta_keywords="Registered Valuers, Global Valuation Summit , Global Summit";
    tempObj.meta_title="Our Registered Valuers: Global Valuation Summit Collaboration";
    tempObj.meta_description="Discover the esteemed partners who contribute to the success of Global Valuation Global Summit. Learn about our collaborative efforts shaping the valuation industry.";
    
  }else if(urlPath.pathname === '/register'){
    tempObj.meta_keywords="Register Now for the Global Valuation Summit 2023";
    tempObj.meta_title="Register Now for the Global Valuation Summit 2023";
    tempObj.meta_description="Secure your spot at the premier valuation event of 2023. Register now for the Global Valuation Summit and join valuation experts from around the world.";
    
  }else if(urlPath.pathname === '/page/terms-and-condition'){
    tempObj.meta_keywords="Global Valuation Summit 2023";
    tempObj.meta_title="Terms & Conditions: Global Valuation Summit Policies";
    tempObj.meta_description="Review our terms and conditions to understand the guidelines for participating in Global Valuation Summit. Learn about our event policies and procedures.";
    
  }else if(urlPath.pathname === '/page/privacy-policy'){
    tempObj.meta_keywords="Global Valuation Summit 2023";
    tempObj.meta_title="Privacy Policy: Global Valuation Summit Data Protection";
    tempObj.meta_description="Learn how we protect your data. Explore Global Valuation Summit's privacy policy to understand how we handle and safeguard your personal information.";
    
  }

  return (
    <div className="App">
      {urlPath.pathname !== '/dashboard' && urlPath.pathname !== '/admin' && urlPath.pathname !== '/adminlogin' && urlPath.pathname !== '/event' && urlPath.pathname !== '/gallery' && urlPath.pathname !== '/banner' && urlPath.pathname !== '/users'
        && urlPath.pathname !== '/cms' && urlPath.pathname !== '/sitesetting' && urlPath.pathname !== '/contactList' && urlPath.pathname !== '/speakers' && urlPath.pathname !== '/popup' && urlPath.pathname !== '/partners' && urlPath.pathname !== '/eventitem'
       && urlPath.pathname !== '/activityList' && urlPath.pathname !== '/registrationList' ?
        <>
          <NavMenu tempObj={tempObj} />
          <Helmet>
            <meta charSet="utf-8" name="keywords" content={tempObj.meta_keywords} />
            <meta name="google-site-verification" content="5TjAQnlod7S28xSKbWdQ1wGf96XqCahiyzQWK6t_ccQ" />
            <meta name="description" content={tempObj.meta_description} />
            <meta name="facebook-domain-verification" content="03qnzi5zqprd0f69j6d1b87j7lllh3" /> 
            <title>{tempObj.meta_title}</title>
            <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
          </Helmet>
          

        </>
        :
        checkData(user) && urlPath.pathname !== '/adminlogin' ?
          <>
            <Header user={user[0]}  permAPI={permAPI} />
            <Sidebar user={user[0]} />
             
          </>
          : ''
      }
      <Routes>
        <Route path="/" element={<Home />} />
        {checkData(user) && <>
          <Route path="/dashboard" element={<Dashboard user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/event" element={<Event user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/gallery" element={<Gallery user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/banner" element={<Banner user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/cms" element={<CMS user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/sitesetting" element={<SiteSetting user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/contactList" element={<ContactList user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/registrationList" element={<RegistartionList user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/activityList" element={<ActivityList user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/speakers" element={<Speakers user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/popup" element={<Popup user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/partners" element={<Partners user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/eventitem" element={<EventItem user={user[0]} permissions={checkData(permissions) && permissions} />} />
          {parseInt(user[0].id) === 1 &&
            <Route path="/users" element={<AllAdmin user={user[0]} />} />
          }
        </>
        }
        <Route path="/adminlogin" element={<Login />} />
        {/* <Route path="/page/:slug" element={<About />} /> */}
        <Route path="/contact" element={<Contact tempObj={tempObj} />} />
        <Route path="/events" element={<EventList />} />
        <Route path="/register" element={<Register />} />
        <Route path="/eventsDetails" element={<EventsDetails />} />
        <Route path="/event/gallery" element={<EventGallery />} />
        <Route path="/page/:slug" element={<CmsPages />} />
        <Route path="/event/registration/response" element={<ResponseP />} />

        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
      {urlPath.pathname !== '/dashboard' && urlPath.pathname !== '/admin' && urlPath.pathname !== '/adminlogin' && urlPath.pathname !== '/event' && urlPath.pathname !== '/gallery' && urlPath.pathname !== '/banner' && urlPath.pathname !== '/users'
        && urlPath.pathname !== '/cms' && urlPath.pathname !== '/sitesetting' && urlPath.pathname !== '/contactList' && urlPath.pathname !== '/speakers' && urlPath.pathname !== '/popup' && urlPath.pathname !== '/activityList' && urlPath.pathname !== '/partners' && urlPath.pathname !== '/eventitem' && urlPath.pathname !== '/registrationList' &&
        <Footer tempObj={tempObj} />
      }
    </div>
  );
}

export default App;
