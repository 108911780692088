

export function changeIntoDate(dateTime){
    
    if(dateTime !== undefined && dateTime !== null){
    let date=new Date(dateTime),
    month = '' + (date.getUTCMonth() + 1),
    day = '' + date.getUTCDate(),
    year = '' + date.getUTCFullYear();

    if(month.length < 2) month = '0' + month;
    if(day.length < 2) day = '0' + day;
     return [day,month,year].join('-');
    }else{
        return ''
    }
}
export function changeIntoDateYmd(dateTime){
    if(dateTime !== undefined && dateTime !== null){
        var date = new Date(dateTime);
        var month = date.getUTCMonth() + 1; //months from 1-12
        var day = date.getUTCDate();
        var year = date.getUTCFullYear();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10){
            day = '0' + day;
        }
        let newdate = year + "-" + month + "-" + day
        return newdate;
    }else{
        return ''
    }
}
export function changeIntoDatedmY(dateTime){
    if(dateTime !== undefined && dateTime !== null){
    let date=new Date(dateTime).toLocaleDateString('sv-SE', {
        day: '2-digit',month: '2-digit',year: 'numeric'});
     return date;
    }else{
        return ''
    }
}

export function changeIntoDatedmYHMS(dateTime){
    if(dateTime !== undefined && dateTime !== null){
    let date=new Date(dateTime).toLocaleDateString('sv-SE', {
        day: '2-digit',month: '2-digit',year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit',});
     return date;
    }else{
        return ''
    }
}