import React, { useState, useEffect } from 'react'
import { Form, Spinner, FormGroup, Label, Card, CardBody, Badge, TabContent, TabPane, } from 'reactstrap'
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { StateList } from '../../StateList';
import classnames from 'classnames';
import { makePaymentpayu } from './Payu';
import { country } from '../../CountryList ';
import { Link } from 'react-router-dom';
import { checkData } from '../../ObjectExist';

const Register = (props) => {
    const [loader, setLoader] = useState(false);
    const [delegate, setDelegate] = useState(false);
    const [delegateMore, setDelegateMore] = useState(false);
    const [activeTab, setActiveTab] = useState('member');
    const [mainTab, setMainTab] = useState('registration');
    const [activeResidential, setActiveResidential] = useState('singleOccupancy');
    const [category, setCategory] = useState('');
    const [id_phone, set_id_phone] = useState('');
    const [id_register_id, set_id_register_id] = useState('');

    let registerFee = 12000
    let registerPayable = 14160
    let registerTax = registerPayable - registerFee

    let delegateOneFee = 6000
    let delegateOnePayable = 7080
    let delegateOneTax = delegateOnePayable - delegateOneFee

    let delegateTwoFee = 12000
    let delegateTwoPayable = 14160
    let delegateTwoTax = delegateTwoPayable - delegateTwoFee

    let residentialSingleOccupancyPayable = 42000
    let residentialSingleOccupancyfee = residentialSingleOccupancyPayable - (18/100) * residentialSingleOccupancyPayable
    let residentialSingleOccupancyTax = residentialSingleOccupancyPayable - residentialSingleOccupancyfee

    let residentialDoubleOccupancyPayable = 50000
    let residentialDoubleOccupancyfee = residentialDoubleOccupancyPayable - (18/100) * residentialDoubleOccupancyPayable
    let residentialDoubleOccupancyTax = residentialDoubleOccupancyPayable - residentialDoubleOccupancyfee

    let residentialDoubleOccupancyTwoPayable = 55000 
    let residentialDoubleOccupancyTwofee =  residentialDoubleOccupancyTwoPayable - (18 / 100) * residentialDoubleOccupancyTwoPayable
    let residentialDoubleOccupancyTwoTax = residentialDoubleOccupancyTwoPayable - residentialDoubleOccupancyTwofee

    // console.log('residentialDoubleOccupancyTwofee', residentialDoubleOccupancyTwofee) 

    let totalResidentialPayable = activeResidential == 'singleOccupancy' ? residentialSingleOccupancyPayable : activeResidential == 'doubleOccupancy' ? residentialDoubleOccupancyPayable : activeResidential == 'doubleOccupancyTwo' ? residentialDoubleOccupancyTwoPayable : residentialSingleOccupancyPayable
    
    let totalResidentialFee = activeResidential == 'singleOccupancy' ? residentialSingleOccupancyfee : activeResidential == 'doubleOccupancy' ? residentialDoubleOccupancyfee : activeResidential == 'doubleOccupancyTwo' ? residentialDoubleOccupancyTwofee : residentialSingleOccupancyfee
    
    let totalResidentialTax = activeResidential == 'singleOccupancy' ? residentialSingleOccupancyTax : activeResidential == 'doubleOccupancy' ? residentialDoubleOccupancyTax : activeResidential == 'doubleOccupancyTwo' ? residentialDoubleOccupancyTwoTax : residentialSingleOccupancyTax
      

    let totalPayable = delegateMore ? registerPayable + delegateTwoPayable : delegate ? registerPayable + delegateOnePayable : registerPayable

    let registerFee2 = category === '1' ? 12000 : category === '2' ? 12000 : category === '3' ? 12000 : category === '4' ? 12000 : ''
    let registerPayable2 = category === '1' ? 14160 : category === '2' ? 14160 : category === '3' ? 14160 : category === '4' ? 14160 : ''
    let registerTax2 = registerPayable2 - registerFee2

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const downloadIDCard=()=>{

        if(id_register_id!==""){
            window.open('https://www.globalvaluationsummit.com/api/download/'+id_register_id+'_id_card.pdf?pathName=id_cards/2023-11-23', '_blank');
        }else{
            Swal.fire({ icon: 'error', title: 'Error', text: "Enter a valid GVS register ID", });

        }
        

    };

    const changeRegisterId=(e)=>{
        var text = e.target.value;
        set_id_register_id(text);
    }

    // const changePhone=(e)=>{
    //     var text = e.target.value;
    //     set_id_phone(text);
    // }

    const { register: registrationInput, handleSubmit: registrationSubmit, formState: { errors }, reset } = useForm();
    const toggleTab = tab => {
        reset();
        setDelegate(false);
        setDelegateMore(false);
        if (activeTab !== tab) setActiveTab(tab);
    }
    const toggleMainTab = tab => {
        if (mainTab !== tab) setMainTab(tab);
        //console.log("mainTab",mainTab);
    }
    const toggleResidential = tab => {
        if (activeResidential !== tab) setActiveResidential(tab);
    }
    const onRegistration = (data) => {
        setLoader(true);
        if (category !== '3') {
            data.country = "India"
        }
        if (activeTab === 'member') {
            data.member_type_id = 1
        } else {
            data.member_type_id = 2
        }
        if(mainTab == 'registration'){
            data.registration_type_id = 1;
        }else{
            data.registration_type_id = 2;
        }
       console.log("before_save",JSON.stringify(data));
        data.activeResidential = activeResidential;
        http.post(`event/registration/save`, data).then((res) => {
            setLoader(true);
            if (res.data.s === 1) {
                let orderId = res.data.order_id;
            //    console.log('mainTab',mainTab);
            //    console.log('activeTab',activeTab);
            //    console.log('totalPayable',totalPayable);
            //    console.log('totalResidentialPayable',totalResidentialPayable);
            //    console.log('registerPayable2',registerPayable2);
            //    console.log('activeResidential',activeResidential);

                let payAmount = (mainTab === 'registration')  ? data.email === 'one@gmail.com' ? 1 : totalPayable : (mainTab === 'residential') ? totalResidentialPayable : registerPayable2
                let myData = { order_id: orderId, email: data.email, first_name: data.first_name, mobile: data.phone, total_amt: payAmount }
               // console.log('payAmount',payAmount);

                //return false;
                http.post(`payment/payu-request`, myData).then((response) => {
                    let callbackURLPAYU = process.env.REACT_APP_PAYU_CALLBACK_SEND_URL
                   // setLoader(false);
                    if (response.data.s === 1) {
                        orderId = res.data.order_id
                        let param = {
                            key: response.data.key,
                            hash: response.data.hash,
                            txnid: orderId,
                            amount: payAmount,
                            productinfo: 'vc_event_register',
                            country: 'India',
                            firstname: data.first_name,
                            udf1: data.phone,
                            phone: data.phone,
                            surl: callbackURLPAYU,
                            furl: callbackURLPAYU,
                            email: data.email,
                            service_provider: 'payu_paisa'
                        }
                        makePaymentpayu(param);
                        reset();
                    }
                }).catch(function (err) {
                    setLoader(false);
                });
                reset();
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
            Swal.fire({ icon: 'error', title: 'Error', text: err, });
        });
    };
    const onMemberData = (e) => {
        //let data = { admin_id: 1, iov_number: e.target.value }
        let data = { admin_id: checkData(props.user) ? props.user.id : '', iov_number: e.target.value }

        http.post(`event/member/data`, data).then((res) => {
            // console.log('data', data)
            if (res.data.s === 1) {
                reset(res.data.data[0])
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    const phoneValidate = (e) => {
        e.stopPropagation();
        let phone = e.target.value;
        if (phone != undefined && phone != '' && phone.length >= 10) {
            //let data = { admin_id: 1, iov_number: e.target.value }
            let data = { admin_id: checkData(props.user) ? props.user.id : '', phone: e.target.value }

            http.post(`event/registration/validate/phone`, data).then((res) => {
                if (res.data.s === 0) {
                    alert("This Phone number already exists with our event Registration");
                    //reset(res.data.data[0]); 
                    //resetField('phone');
                    reset({
                        phone: "",
                    }, {
                        keepErrors: true,
                        keepDirty: true,
                        keepIsSubmitted: false,
                        keepTouched: false,
                        keepIsValid: false,
                        keepSubmitCount: false,
                    });


                }

            }).catch(function (err) {
                setLoader(false);
            });
        }

    };

    const emailValidate = (e) => {
        e.stopPropagation();
        let email = e.target.value;
        if (email != undefined && email != '') {
            //let data = { admin_id: 1, iov_number: e.target.value }
            let data = { admin_id: checkData(props.user) ? props.user.id : '', email: e.target.value }

            http.post(`event/registration/validate/email`, data).then((res) => {
                if (res.data.s === 0) {
                    alert("This Email  already exists with our event Registration");
                    reset({
                        email: "",
                    }, {
                        keepErrors: true,
                        keepDirty: true,
                        keepIsSubmitted: false,
                        keepTouched: false,
                        keepIsValid: false,
                        keepSubmitCount: false,
                    });
                }

            }).catch(function (err) {
                setLoader(false);
            });
        }

    };

    const inputCategory = (e) => {
        setCategory(e.target.value);
    }
    const inputChange = (e) => {
        if (parseInt(e.target.value) === 1) {
            setDelegate(true);
        } else {
            setDelegate(false);
            setDelegateMore(false);
        }
    }
    const addMore = () => {
        setDelegateMore(true);
    }
    return (
        <div>
            {loader && <div className="formLoader"><Spinner /></div>}
            <div className="breadcumb-wrapper" style={{ backgroundImage: 'url(./img/Register-Banner.jpg)' }}>
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="d-none breadcumb-title">Register</h1>
                        <ul className="d-none breadcumb-menu">
                            <li><a href="./">Home</a></li>
                            <li className="active">Register</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="bg-smoke space pt-0 bg-auto-top-left" style={{ backgroundImage: 'url(./img/contact_bg_1.jpg)' }}>
                <div className="space-top pt-3">
                    <div className="container">
                        <div className="title-area text-center">
                            <span className="sub-title"></span>
                            <h2 className="sec-title d-none">Register</h2>
                            <span className="shape-title"></span>
                        </div>
                        <div className="row justify-content-center">

                            {/* <div class="row justify-content-center">
                            <div class="col-xl-12 col-lg-12">
                                <div class="contact-tab-wrapper">
                                    <div class="nav justify-content-center tab-style5" id="tab-style5" role="tablist">
                                        <button class="tab-btn active" id="nav-one-tab" data-bs-toggle="tab" data-bs-target="#nav-one" type="button" role="tab" aria-controls="nav-one" aria-selected="true">Event Registration</button>
                                    </div>
                                    <div class="tab-content contact-tab-area" id="contact-tab-area">
                                        <div class="tab-pane fade show active" id="nav-one" role="tabpanel" aria-labelledby="nav-one-tab">
                                            <div class="contact-tab-content">
                                                <i class="fas fa-ticket"></i>
                                                <h3 class="contact-tab_title">Events are Closed on IVC Website</h3>
                                            </div>
                                        </div>
                                         
                                         
                                    </div>
                                </div>
                            </div>
                             
                        </div> */}

                            <div className="col-xl-12 col-lg-12">
                                <div className="">
                                    <div className="nav tab-style2 mb-3" role="tablist">
                                        <button className={`${classnames({ active: mainTab === 'registration' })} tab-btn`} onClick={() => { toggleMainTab('registration'); }}>Registration Closed</button>
                                        <button className={`${classnames({ active: mainTab === 'residential' })} tab-btn d-none`} onClick={() => { toggleMainTab('residential'); }}>Residential Registration</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 d-none">
                                <Card className="border-0 py-3">
                                    <CardBody>

                                        {mainTab === 'registration' &&
                                            <>
                                                <div className='row'>
                                                    <div className="col-xl-6 col-lg-6 text-center">
                                                        <input
                                                            type="radio"
                                                            name="tab"
                                                            className={classnames({ active: activeTab === 'member' })}
                                                            onClick={() => toggleTab('member')}
                                                            id="forMember"
                                                            checked={activeTab === 'member' ? 'checked' : ''}
                                                        />
                                                        <Label for="forMember">
                                                            Member
                                                        </Label>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 text-center">
                                                        <input
                                                            type="radio"
                                                            name="tab"
                                                            className={classnames({ active: activeTab === 'non-member' })}
                                                            onClick={() => toggleTab('non-member')}
                                                            id="forNonMember"
                                                            checked={activeTab === 'non-member' ? 'checked' : ''}
                                                        />
                                                        <Label for="forNonMember">
                                                            Non Member
                                                        </Label>
                                                    </div>
                                                </div>
                                                <TabContent activeTab={activeTab} className="pb-0">
                                                    <TabPane tabId="member">
                                                        {activeTab === 'member' &&
                                                            <Form className="ajax-contact contact-form">
                                                                <div className="row gx-20">
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("iov_number", {
                                                                            required: 'Please Enter IOV Membership No.',
                                                                            maxLength: { value: 7, message: "Please Enter Max 7 Character" },
                                                                        })} className="form-control" id="membershipno" placeholder="IOV Membership No." onChange={(e) => onMemberData(e)} />
                                                                        {errors.iov_number && <span className="text-danger d-block error">{errors.iov_number.message}</span>}
                                                                        <small style={{ "font-size": ".65em" }} class="text-muted">F/A/L then - and then your membership no. without space (Exp: F-00000) max 7 characters </small>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("ibbi_no", {
                                                                            minLength: { value: 21, message: "Please Enter Min 21 Character" },
                                                                            maxLength: { value: 21, message: "Please Enter Max 21 Character" },
                                                                        })} minLength={21} maxLength={21} className="form-control" id="registno" placeholder="IBBI Registration No." />
                                                                        {errors.ibbi_no && <span className="text-danger d-block error">{errors.ibbi_no.message}</span>}
                                                                        <small style={{ "font-size": ".65em" }} class="text-muted">Enter 21 characters as prescribed by IBBI without space (Exp: IBBI/RV/00/YYYY/00000) </small>

                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("first_name", { required: 'Please Enter First Name' })} className="form-control" id="name" placeholder="Your First Name" />
                                                                        {errors.first_name && <span className="text-danger d-block error">{errors.first_name.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("last_name", { required: 'Please Enter Last Name' })} className="form-control" id="name" placeholder="Your Last Name" />
                                                                        {errors.last_name && <span className="text-danger d-block error">{errors.last_name.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("email", { required: 'Please Enter Email', pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="email" className="form-control" onChange={(e) => emailValidate(e)} id="email" placeholder="Your Email" />
                                                                        {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("phone", {
                                                                            required: 'Please Enter Phone',
                                                                            minLength: { value: 10, message: "Please Enter Min 10 Digit Mobile Number" },
                                                                            maxLength: { value: 10, message: "Please Enter Max 10 Digit Mobile Number" },
                                                                        })} type="number" onChange={(e) => phoneValidate(e)} className="form-control" id="number" placeholder="Phone Number" />
                                                                        {errors.phone && <span className="text-danger d-block error">{errors.phone.message}</span>}
                                                                        <small style={{ "font-size": ".65em" }} class="text-muted"> Enter valid 10 digits phone number </small>

                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("address", { required: 'Please Enter Street Address' })} type="address" className="form-control" id="address" placeholder="Street Address" />
                                                                        {errors.address && <span className="text-danger d-block error">{errors.address.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("city", { required: 'Please Enter City' })} className="form-control" id="city" placeholder="City" />
                                                                        {errors.city && <span className="text-danger d-block error">{errors.city.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <div className="position-relative">
                                                                            {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                                                            <select {...registrationInput("state", { required: 'Please Select State' })} className="form-control" id="state">
                                                                                <option value="">Select State</option>
                                                                                {StateList.map((item, index) => (
                                                                                    <option value={item.state_name}>{item.state_name}</option>
                                                                                ))
                                                                                }
                                                                            </select>
                                                                            {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("pincode", {
                                                                            required: 'Please Enter postal code',
                                                                            minLength: { value: 6, message: "Min 6 Digit allowed" },
                                                                            maxLength: { value: 6, message: "Max 6 Digit allowed" },
                                                                        })} className="form-control" type="number" id="pincode" placeholder="Postal Code" />
                                                                        {errors.pincode && <span className="text-danger d-block error">{errors.pincode.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("gst_no")} className="form-control" id="gst" placeholder="GST No." />
                                                                        {errors.gst_no && <span className="text-danger d-block error">{errors.gst_no.message}</span>}
                                                                        <small style={{ "font-size": ".65em" }} class="text-muted"> If you don't have GST number, please mention NA </small>
                                                                    </div>
                                                                    <div className="form-group col-md-12 yesno">
                                                                        <FormGroup check className="ps-0">
                                                                            <Label>Accompanying the delegates</Label>
                                                                            <div>
                                                                                <span className="form-check2 me-3">
                                                                                    <input className="mx-2" {...registrationInput("delegates")} type="radio" value="1" id="yes" onChange={(e) => inputChange(e)} />
                                                                                    <Label for="yes">Yes</Label>
                                                                                </span>
                                                                                <span className="form-check2">
                                                                                    <input className="mx-2" {...registrationInput("delegates")} type="radio" value="0" id="no" onChange={(e) => inputChange(e)} />
                                                                                    <Label for="no">No</Label>
                                                                                </span>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                    {delegate &&
                                                                        <>
                                                                            <div className="form-group col-md-12 yesno">
                                                                                <FormGroup check className="ps-0">
                                                                                    <Label>Accompanying delegate - 1 Details</Label>
                                                                                </FormGroup>
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <input {...registrationInput("delegate_name_1", { required: 'Please Enter Name of Accompanying delegate - 1' })} className="form-control" id="nameone" placeholder="Name of Accompanying delegate - 1" />
                                                                                {errors.delegate_name_1 && <span className="text-danger d-block error">{errors.delegate_name_1.message}</span>}
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <input {...registrationInput("delegate_age_1", {
                                                                                    required: 'Please Enter Age of Accompanying delegate - 1',
                                                                                    minLength: { value: 2, message: "Min 2 Digit " },
                                                                                    maxLength: { value: 2, message: "Max 2 Digit " },
                                                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid age' }
                                                                                })} className="form-control" type="number" id="delegate_age_1" placeholder="Age of Accompanying delegate - 1" />
                                                                                {errors.delegate_age_1 && <span className="text-danger d-block error">{errors.delegate_age_1.message}</span>}
                                                                            </div>
                                                                            <div className="form-group col-md-12 yesno">
                                                                                <FormGroup check className="ps-0">
                                                                                    <Label>Gender of Accompanying delegate - 1</Label>
                                                                                    <div>
                                                                                        <span className="form-check-genderone me-3">
                                                                                            <input className="mx-2" {...registrationInput("delegate_gender_1")} type="radio" value="1" id="maleone" defaultChecked />
                                                                                            <Label for="maleone">Male</Label>
                                                                                        </span>
                                                                                        <span className="form-check-genderone">
                                                                                            <input className="mx-2" {...registrationInput("delegate_gender_1")} type="radio" value="0" id="femaleone" />
                                                                                            <Label for="femaleone">Female</Label>
                                                                                        </span>
                                                                                    </div>
                                                                                </FormGroup>
                                                                            </div>
                                                                            {delegateMore &&
                                                                                <>
                                                                                    <div className="form-group col-md-12 yesno border-top pt-3">
                                                                                        <FormGroup check className="ps-0">
                                                                                            <Label>Accompanying delegate - 2 Details</Label>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                    <div className="form-group col-md-6">
                                                                                        <input {...registrationInput("delegate_name_2", { required: 'Please Enter Name of Accompanying delegate - 2' })} className="form-control" id="nametwo" placeholder="Name of Accompanying delegate - 2" />
                                                                                        {errors.delegate_name_2 && <span className="text-danger d-block error">{errors.delegate_name_2.message}</span>}
                                                                                    </div>
                                                                                    <div className="form-group col-md-6">
                                                                                        <input {...registrationInput("delegate_age_2", {
                                                                                            required: 'Please Enter Age of Accompanying delegate - 2',
                                                                                            minLength: { value: 2, message: "Min 2 Digit " },
                                                                                            maxLength: { value: 2, message: "Max 2 Digit " },
                                                                                            pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid age' }
                                                                                        })} className="form-control" type="number" id="delegate_age_2" placeholder="Age of Accompanying delegate - 2" />
                                                                                        {errors.delegate_age_2 && <span className="text-danger d-block error">{errors.delegate_age_2.message}</span>}
                                                                                    </div>
                                                                                    <div className="form-group col-md-12 yesno">
                                                                                        <FormGroup check className="ps-0">
                                                                                            <Label>Gender of Accompanying delegate - 2</Label>
                                                                                            <div>
                                                                                                <span className="form-check-genderone me-3">
                                                                                                    <input className="mx-2" {...registrationInput("delegate_gender_2")} type="radio" value="1" id="maletwo" defaultChecked />
                                                                                                    <Label for="maletwo">Male</Label>
                                                                                                </span>
                                                                                                <span className="form-check-genderone">
                                                                                                    <input className="mx-2" {...registrationInput("delegate_gender_2")} type="radio" value="0" id="femaletwo" />
                                                                                                    <Label for="femaletwo">Female</Label>
                                                                                                </span>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {!delegateMore &&
                                                                                <div className="form-group col-md-12 yesno">
                                                                                    <FormGroup check className="ps-0">
                                                                                        <Label className="cursor-pointer"><Badge color="primary" onClick={addMore}>Add More Accompanying delegate</Badge></Label>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                                <p className="form-messages"></p>
                                                            </Form>
                                                        }
                                                    </TabPane>
                                                    <TabPane tabId="non-member">
                                                        {activeTab === 'non-member' &&
                                                            <Form className="ajax-contact contact-form">
                                                                <div className="row gx-20">
                                                                    <div className="form-group col-md-6">
                                                                        <div className="position-relative">
                                                                            {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                                                            <select {...registrationInput("non_member_category_id", { required: 'Please Select Category' })} className="form-control" id="category" onChange={(e) => inputCategory(e)}>
                                                                                <option value="">Select Category</option>
                                                                                <option value="1">IBBI Registered Valuer</option>
                                                                                 {/* <option value="2">Sponsor Deligate</option> */}
                                                                                {/* <option value="3">Foreign Deligates</option> */}
                                                                                <option value="4">Others</option>
                                                                            </select>
                                                                            {errors.non_member_category_id && <span className="text-danger d-block error">{errors.non_member_category_id.message}</span>}
                                                                        </div>
                                                                    </div>
                                                                    {category === '1' &&
                                                                        <div className="form-group col-md-6">
                                                                            <input {...registrationInput("ibbi_no")} minLength={21} maxLength={21} className="form-control" id="registno" placeholder="IBBI Registration No." />
                                                                            {errors.ibbi_no && <span className="text-danger d-block error">{errors.ibbi_no.message}</span>}
                                                                        </div>
                                                                    }
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("first_name", { required: 'Please Enter First Name' })} className="form-control" id="name" placeholder="Your First Name" />
                                                                        {errors.first_name && <span className="text-danger d-block error">{errors.first_name.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("last_name", { required: 'Please Enter Last Name' })} className="form-control" id="name" placeholder="Your Last Name" />
                                                                        {errors.last_name && <span className="text-danger d-block error">{errors.last_name.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("email", { required: 'Please Enter Email', pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="email" onChange={(e) => emailValidate(e)} className="form-control" id="email" placeholder="Your Email" />
                                                                        {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("phone", {
                                                                            required: 'Please Enter Phone',
                                                                            minLength: { value: 10, message: "Please Enter Min 10 Digit Mobile Number" },
                                                                            maxLength: { value: 10, message: "Please Enter Max 10 Digit Mobile Number" },
                                                                        })} type="number" onChange={(e) => phoneValidate(e)} className="form-control" id="number" placeholder="Phone Number" />
                                                                        {errors.phone && <span className="text-danger d-block error">{errors.phone.message}</span>}
                                                                        <small style={{ "font-size": ".65em" }} class="text-muted"> Enter valid 10 digits phone number </small>

                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("address", { required: 'Please Enter Street Address' })} type="address" className="form-control" id="address" placeholder="Street Address" />
                                                                        {errors.address && <span className="text-danger d-block error">{errors.address.message}</span>}
                                                                    </div>
                                                                    {category === '3' ?
                                                                        <>
                                                                            <div className="form-group col-md-6">
                                                                                <select {...registrationInput("country", { required: 'Please Select Country' })} className="form-control" id="country">
                                                                                    <option value="">Select Country</option>
                                                                                    {Object.entries(country).map(([key, value]) => (
                                                                                        <option value={value}>{value}</option>
                                                                                    ))}
                                                                                </select>
                                                                                {errors.country && <span className="text-danger d-block error">{errors.country.message}</span>}
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <input {...registrationInput("passport_no", { required: 'Please Enter Passport No' })} className="form-control" id="passport_no" placeholder="Passport No" />
                                                                                {errors.passport_no && <span className="text-danger d-block error">{errors.passport_no.message}</span>}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="form-group col-md-6">
                                                                                <input {...registrationInput("city", { required: 'Please Enter City' })} className="form-control" id="city" placeholder="City" />
                                                                                {errors.city && <span className="text-danger d-block error">{errors.city.message}</span>}
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <div className="position-relative">
                                                                                    {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                                                                    <select {...registrationInput("state", { required: 'Please Select State' })} className="form-control" id="state">
                                                                                        <option value="">Select State</option>
                                                                                        {StateList.map((item, index) => (
                                                                                            <option value={item.state_name}>{item.state_name}</option>
                                                                                        ))
                                                                                        }
                                                                                    </select>
                                                                                    {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("pincode", {
                                                                            required: 'Please Enter postal code',
                                                                            minLength: { value: 6, message: "Min 6 Digit allowed" },
                                                                            maxLength: { value: 6, message: "Max 6 Digit allowed" },
                                                                        })} className="form-control" type="number" id="pincode" placeholder="Postal Code" />

                                                                        {errors.pincode && <span className="text-danger d-block error">{errors.pincode.message}</span>}
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("gst_no")} className="form-control" id="gst" placeholder="GST No." />
                                                                        {errors.gst_no && <span className="text-danger d-block error">{errors.gst_no.message}</span>}
                                                                    </div>
                                                                </div>
                                                                <p className="form-messages"></p>
                                                            </Form>
                                                        }
                                                    </TabPane>
                                                </TabContent>
                                            </>
                                        }
                                        {mainTab === 'residential' &&
                                            <>
                                                <div className='row'>
                                                    <div className="col-xl-12 col-lg-12 text-left my-3">
                                                        <input
                                                            type="radio"
                                                            name="tab"
                                                            className={classnames({ active: activeResidential === 'singleOccupancy' })}
                                                            onClick={() => toggleResidential('singleOccupancy')}
                                                            id="forSingleOccupancy"
                                                            checked={activeResidential === 'singleOccupancy' ? 'checked' : ''}
                                                        />
                                                        <Label for="forSingleOccupancy">
                                                            Individual Registration fee with three night stay and all meals (Single occupancy)
                                                        </Label>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 text-left my-3">
                                                        <input
                                                            type="radio"
                                                            name="tab"
                                                            className={classnames({ active: activeResidential === 'doubleOccupancy' })}
                                                            onClick={() => toggleResidential('doubleOccupancy')}
                                                            id="forDoubleOccupancy"
                                                            checked={activeResidential === 'doubleOccupancy' ? 'checked' : ''}
                                                        />
                                                        <Label for="forDoubleOccupancy">
                                                            Registration fee of delegate with one family member with three night stay and all meals (Double occupancy)
                                                        </Label>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 text-left my-3">
                                                        <input
                                                            type="radio"
                                                            name="tab"
                                                            className={classnames({ active: activeResidential === 'doubleOccupancyTwo' })}
                                                            onClick={() => toggleResidential('doubleOccupancyTwo')}
                                                            id="forDoubleOccupancyTwo"
                                                            checked={activeResidential === 'doubleOccupancyTwo' ? 'checked' : ''}
                                                        />
                                                        <Label for="forDoubleOccupancyTwo">
                                                           Two delegates registration fee with three night stay and all meals (Double occupancy)
                                                        </Label>
                                                    </div>
                                                </div>

                                                <Form className="ajax-contact contact-form">
                                                    <div className="row gx-20">
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("iov_number", {
                                                                required: 'Please Enter IOV Membership No.',
                                                                maxLength: { value: 7, message: "Please Enter Max 7 Character" },
                                                            })} className="form-control" id="membershipno" placeholder="IOV Membership No." onChange={(e) => onMemberData(e)} />
                                                            {errors.iov_number && <span className="text-danger d-block error">{errors.iov_number.message}</span>}
                                                            <small style={{ "font-size": ".65em" }} class="text-muted">F/A/L then - and then your membership no. without space (Exp: F-00000) max 7 characters </small>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("ibbi_no", {
                                                                minLength: { value: 21, message: "Please Enter Min 21 Character" },
                                                                maxLength: { value: 21, message: "Please Enter Max 21 Character" },
                                                            })} minLength={21} maxLength={21} className="form-control" id="registno" placeholder="IBBI Registration No." />
                                                            {errors.ibbi_no && <span className="text-danger d-block error">{errors.ibbi_no.message}</span>}
                                                            <small style={{ "font-size": ".65em" }} class="text-muted">Enter 21 characters as prescribed by IBBI without space (Exp: IBBI/RV/00/YYYY/00000) </small>

                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("first_name", { required: 'Please Enter First Name' })} className="form-control" id="name" placeholder="Your First Name" />
                                                            {errors.first_name && <span className="text-danger d-block error">{errors.first_name.message}</span>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("last_name", { required: 'Please Enter Last Name' })} className="form-control" id="name" placeholder="Your Last Name" />
                                                            {errors.last_name && <span className="text-danger d-block error">{errors.last_name.message}</span>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("email", { required: 'Please Enter Email', pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' } })} type="email" className="form-control" onChange={(e) => emailValidate(e)} id="email" placeholder="Your Email" />
                                                            {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("phone", {
                                                                required: 'Please Enter Phone',
                                                                minLength: { value: 10, message: "Please Enter Min 10 Digit Mobile Number" },
                                                                maxLength: { value: 10, message: "Please Enter Max 10 Digit Mobile Number" },
                                                            })} type="number" onChange={(e) => phoneValidate(e)} className="form-control" id="number" placeholder="Phone Number" />
                                                            {errors.phone && <span className="text-danger d-block error">{errors.phone.message}</span>}
                                                            <small style={{ "font-size": ".65em" }} class="text-muted"> Enter valid 10 digits phone number </small>

                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("address", { required: 'Please Enter Street Address' })} type="address" className="form-control" id="address" placeholder="Street Address" />
                                                            {errors.address && <span className="text-danger d-block error">{errors.address.message}</span>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("city", { required: 'Please Enter City' })} className="form-control" id="city" placeholder="City" />
                                                            {errors.city && <span className="text-danger d-block error">{errors.city.message}</span>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <div className="position-relative">
                                                                {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                                                <select {...registrationInput("state", { required: 'Please Select State' })} className="form-control" id="state">
                                                                    <option value="">Select State</option>
                                                                    {StateList.map((item, index) => (
                                                                        <option value={item.state_name}>{item.state_name}</option>
                                                                    ))
                                                                    }
                                                                </select>
                                                                {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("pincode", {
                                                                required: 'Please Enter postal code',
                                                                minLength: { value: 6, message: "Min 6 Digit allowed" },
                                                                maxLength: { value: 6, message: "Max 6 Digit allowed" },
                                                            })} className="form-control" type="number" id="pincode" placeholder="Postal Code" />
                                                            {errors.pincode && <span className="text-danger d-block error">{errors.pincode.message}</span>}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <input {...registrationInput("gst_no")} className="form-control" id="gst" placeholder="GST No." />
                                                            {errors.gst_no && <span className="text-danger d-block error">{errors.gst_no.message}</span>}
                                                            <small style={{ "font-size": ".65em" }} class="text-muted"> If you don't have GST number, please mention NA </small>
                                                        </div>
                                                        <div className="form-group col-md-12 yesno">
                                                            <FormGroup check className="ps-0">
                                                                <Label>Accompanying the delegates</Label>
                                                                <div>
                                                                    <span className="form-check2 me-3">
                                                                        <input className="mx-2" {...registrationInput("delegates")} type="radio" value="1" id="yes" onChange={(e) => inputChange(e)} />
                                                                        <Label for="yes">Yes</Label>
                                                                    </span>
                                                                    <span className="form-check2">
                                                                        <input className="mx-2" {...registrationInput("delegates")} type="radio" value="0" id="no" onChange={(e) => inputChange(e)} />
                                                                        <Label for="no">No</Label>
                                                                    </span>
                                                                </div>
                                                            </FormGroup>
                                                        </div>
                                                        {delegate &&
                                                            <>
                                                                <div className="form-group col-md-12 yesno">
                                                                    <FormGroup check className="ps-0">
                                                                        <Label>Accompanying delegate - 1 Details</Label>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <input {...registrationInput("delegate_name_1", { required: 'Please Enter Name of Accompanying delegate - 1' })} className="form-control" id="nameone" placeholder="Name of Accompanying delegate - 1" />
                                                                    {errors.delegate_name_1 && <span className="text-danger d-block error">{errors.delegate_name_1.message}</span>}
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <input {...registrationInput("delegate_age_1", {
                                                                        required: 'Please Enter Age of Accompanying delegate - 1',
                                                                        minLength: { value: 2, message: "Min 2 Digit " },
                                                                        maxLength: { value: 2, message: "Max 2 Digit " },
                                                                        pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid age' }
                                                                    })} className="form-control" type="number" id="delegate_age_1" placeholder="Age of Accompanying delegate - 1" />
                                                                    {errors.delegate_age_1 && <span className="text-danger d-block error">{errors.delegate_age_1.message}</span>}
                                                                </div>
                                                                <div className="form-group col-md-12 yesno">
                                                                    <FormGroup check className="ps-0">
                                                                        <Label>Gender of Accompanying delegate - 1</Label>
                                                                        <div>
                                                                            <span className="form-check-genderone me-3">
                                                                                <input className="mx-2" {...registrationInput("delegate_gender_1")} type="radio" value="1" id="maleone" defaultChecked />
                                                                                <Label for="maleone">Male</Label>
                                                                            </span>
                                                                            <span className="form-check-genderone">
                                                                                <input className="mx-2" {...registrationInput("delegate_gender_1")} type="radio" value="0" id="femaleone" />
                                                                                <Label for="femaleone">Female</Label>
                                                                            </span>
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                                {delegateMore &&
                                                                    <>
                                                                        <div className="form-group col-md-12 yesno border-top pt-3">
                                                                            <FormGroup check className="ps-0">
                                                                                <Label>Accompanying delegate - 2 Details</Label>
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <input {...registrationInput("delegate_name_2", { required: 'Please Enter Name of Accompanying delegate - 2' })} className="form-control" id="nametwo" placeholder="Name of Accompanying delegate - 2" />
                                                                            {errors.delegate_name_2 && <span className="text-danger d-block error">{errors.delegate_name_2.message}</span>}
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <input {...registrationInput("delegate_age_2", {
                                                                                required: 'Please Enter Age of Accompanying delegate - 2',
                                                                                minLength: { value: 2, message: "Min 2 Digit " },
                                                                                maxLength: { value: 2, message: "Max 2 Digit " },
                                                                                pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid age' }
                                                                            })} className="form-control" type="number" id="delegate_age_2" placeholder="Age of Accompanying delegate - 2" />
                                                                            {errors.delegate_age_2 && <span className="text-danger d-block error">{errors.delegate_age_2.message}</span>}
                                                                        </div>
                                                                        <div className="form-group col-md-12 yesno">
                                                                            <FormGroup check className="ps-0">
                                                                                <Label>Gender of Accompanying delegate - 2</Label>
                                                                                <div>
                                                                                    <span className="form-check-genderone me-3">
                                                                                        <input className="mx-2" {...registrationInput("delegate_gender_2")} type="radio" value="1" id="maletwo" defaultChecked />
                                                                                        <Label for="maletwo">Male</Label>
                                                                                    </span>
                                                                                    <span className="form-check-genderone">
                                                                                        <input className="mx-2" {...registrationInput("delegate_gender_2")} type="radio" value="0" id="femaletwo" />
                                                                                        <Label for="femaletwo">Female</Label>
                                                                                    </span>
                                                                                </div>
                                                                            </FormGroup>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {!delegateMore &&
                                                                    <div className="form-group col-md-12 yesno">
                                                                        <FormGroup check className="ps-0">
                                                                            <Label className="cursor-pointer"><Badge color="primary" onClick={addMore}>Add More Accompanying delegate</Badge></Label>
                                                                        </FormGroup>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                    <p className="form-messages"></p>
                                                </Form>
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-xl-5 col-lg-5 d-none">
                                <Card className="border-0 py-3 h-100">
                                    {mainTab === 'residential' && activeResidential === 'singleOccupancy' || activeResidential === 'doubleOccupancy' || activeResidential === 'doubleOccupancyTwo' ?
                                        <CardBody>
                                            <div className="priceBreakUp-base-orderSummary ajax-contact contact-form">
                                                <div className="priceDetail-base-row">
                                                    <span>Event Register Fees</span>
                                                    <span className="priceDetail-base-value ">
                                                        <span>₹ {totalResidentialFee}</span>
                                                    </span>
                                                </div>
                                                <div className="priceDetail-base-row">
                                                    <span>Tax</span>
                                                    <span className="priceDetail-base-value ">
                                                        <span>₹ {totalResidentialTax}</span>
                                                    </span>
                                                </div>
                                                <div className="priceDetail-base-total">
                                                    <span>Total</span>
                                                    <span className="priceDetail-base-value ">
                                                        <span>₹ {totalResidentialPayable}</span>
                                                        
                                                    </span>
                                                </div>
                                                <div>
                                                    <FormGroup>
                                                        <FormGroup check className="ps-0 mt-3">
                                                            <Label check><input {...registrationInput("privacy_policy", { required: 'Please Accept the terms and conditions' })} type="checkbox" value="accepted" className="mr-1" /> I agree to the <Link to="/page/tandc-registration">terms and conditions</Link> </Label>
                                                            {errors.privacy_policy && <span className="text-danger d-block error">{errors.privacy_policy.message}</span>}
                                                        </FormGroup>
                                                    </FormGroup>
                                                </div>
                                                <div className="form-btn my-3">
                                                    <button className="vs-btn" onClick={registrationSubmit(onRegistration)} >Submit</button>
                                                </div>
                                            </div>
                                        </CardBody> :
                                        <>
                                            {activeTab === 'member' ?
                                                <CardBody>
                                                    <div className="priceBreakUp-base-orderSummary ajax-contact contact-form">
                                                        <div className="priceDetail-base-row">
                                                            <span>Event Register Fees</span>
                                                            <span className="priceDetail-base-value ">
                                                                <span>₹ {registerFee}</span>
                                                            </span>
                                                        </div>
                                                        <div className="priceDetail-base-row">
                                                            <span>Tax</span>
                                                            <span className="priceDetail-base-value ">
                                                                <span>₹ {registerTax}</span>
                                                            </span>
                                                        </div>
                                                        <div className="priceDetail-base-total">
                                                            <span>Total</span>
                                                            <span className="priceDetail-base-value ">
                                                                <span>₹ {registerPayable}</span>
                                                            </span>
                                                        </div>
                                                        {delegate &&
                                                            <>
                                                                <div className="priceDetail-base-row mt-5">
                                                                    <span>For {delegateMore ? 'Two' : 'One'} Accompanying Delegate</span>
                                                                    <span className="priceDetail-base-value ">
                                                                        <span>₹ {delegateMore ? delegateTwoFee : delegateOneFee}</span>
                                                                    </span>
                                                                </div>
                                                                <div className="priceDetail-base-row">
                                                                    <span>Tax</span>
                                                                    <span className="priceDetail-base-value ">
                                                                        <span>₹ {delegateMore ? delegateTwoTax : delegateOneTax}</span>
                                                                    </span>
                                                                </div>
                                                                <div className="priceDetail-base-total">
                                                                    <span>Net Payable</span>
                                                                    <span className="priceDetail-base-value ">
                                                                        <span>₹ {totalPayable}</span>
                                                                    </span>
                                                                </div>
                                                            </>
                                                        }
                                                        <div>
                                                            <FormGroup>
                                                                <FormGroup check className="ps-0 mt-3">
                                                                    <Label check><input {...registrationInput("privacy_policy", { required: 'Please Accept the terms and conditions' })} type="checkbox" value="accepted" className="mr-1" />I agree to the <Link target="_blank" to="/page/tandc-registration">terms and conditions</Link> </Label>
                                                                    {errors.privacy_policy && <span className="text-danger d-block error">{errors.privacy_policy.message}</span>}
                                                                </FormGroup>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="form-btn my-3">
                                                            <button className="vs-btn" onClick={registrationSubmit(onRegistration)} >Submit</button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                                :
                                                <CardBody>
                                                    <div className="priceBreakUp-base-orderSummary ajax-contact contact-form">
                                                        <div className="priceDetail-base-row">
                                                            <span>Event Register Fees</span>
                                                            <span className="priceDetail-base-value ">
                                                                <span>₹ {registerFee2}</span>
                                                            </span>
                                                        </div>
                                                        <div className="priceDetail-base-row">
                                                            <span>Tax</span>
                                                            <span className="priceDetail-base-value ">
                                                                <span>₹ {registerTax2}</span>
                                                            </span>
                                                        </div>
                                                        <div className="priceDetail-base-total">
                                                            <span>Total</span>
                                                            <span className="priceDetail-base-value ">
                                                                <span>₹ {registerPayable2}</span>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <FormGroup>
                                                                <FormGroup check className="ps-0 mt-3">
                                                                    <Label check><input {...registrationInput("privacy_policy", { required: 'Please Accept the terms and conditions' })} type="checkbox" value="accepted" className="mr-1" />I agree to the <Link to="/page/tandc-registration">terms and conditions</Link> </Label>
                                                                    {errors.privacy_policy && <span className="text-danger d-block error">{errors.privacy_policy.message}</span>}
                                                                </FormGroup>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="form-btn my-3">
                                                            <button className="vs-btn" onClick={registrationSubmit(onRegistration)} >Submit</button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            }
                                        </>
                                    }

                                         
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                                                                 <div className="row gx-20 text-center px-5">
                                                                <h2 className="text-center">Download ID Card</h2>

                                                                    <div className="form-group col-md-6">
                                                                        <input {...registrationInput("register_id", { required: 'Please Enter Register ID Number' })} onChange={e => changeRegisterId(e)} value={id_register_id} className="form-control" id="register_id" placeholder="Your GVS Register ID Start with GVS-XXXX" />
                                                                        <br></br> <small>Check your email for GVS register id</small>
                                                                     </div>
                                                                    {/* <div className="form-group col-md-6">
                                                                        <input {...registrationInput("mobile_no",{ required: 'Please Enter Your Mobile Number' })} onChange={e => changePhone(e)} value={id_phone} className="form-control" id="phone" placeholder="Mobile Number At the time of Registration" />
                                                                     </div> */}
                                                                     <div class="form-group col-md-6">
                                                                         <div class="btn-box">
                                                                            <a class="theme-btn btn-style-one" href='#' onClick={() => { downloadIDCard(); }}>
                                                                                <span class="btn-title"><i class="flaticon-chair"></i> Submit</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                </div>

                                                                
                                                                <p className="form-messages"></p>
             </section>
        </div>
    )
}

export default Register