import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button, FormGroup, Form, Label, Col, CardBody, Table, Modal, ModalHeader, ModalBody, Spinner, } from 'reactstrap'
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { checkData } from '../../ObjectExist';
import { changeIntoDatedmY } from '../../utlis';
import PermissionNotAllowed from './PermissionNotAllowed';
import JoditEditor from "jodit-react";


const CMS = (props) => {
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const [slugs, setSlugs] = useState('')
    const [pageId, setpageId] = useState('')
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});
    const [editmodal, setEditmodal] = useState(false);
    const [addmodal, setAddmodal] = useState(false);
    const [editData, setEditData] = useState({});

    const { register: editInput, handleSubmit: editSubmit, formState: { errors }, reset } = useForm();
    const { register: addInput, handleSubmit: addSubmit, formState: { errorsadd }, reset: resetadd } = useForm();
    const toggleAdd = () => {
        setContent('');
        setAddmodal(!addmodal);
    }
    const toggleEdit = (id) => {
        setEditData({})

        setEditmodal(!editmodal);
        setContent('')
        let data = { admin_id: checkData(props.user) ? props.user.id : '', id: id }
        setLoader(true);
        http.post(`pages/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {

                res.data.data[0].status === true ? res.data.data[0].status = '1' : res.data.data[0].status = '0'
                console.log('status',res.data.data[0].status);
                setEditData(res.data.data[0])

                setContent(res.data.data[0].long_desc);
                setpageId(res.data.data[0].id);
                setSlugs(res.data.data[0].slug);
                reset(res.data.data[0])
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`pages/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, []);
    const onAddPages = (data) => {
        setLoader(true);
        data.long_desc = content
        data.admin_id = checkData(props.user) ? props.user.id : ''
        http.post(`pages/add`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg, });
                onApiInteg(); setAddmodal(false);
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    const onEditPages = (data) => {
        setLoader(true);
        data.long_desc = content
        data.page_id = pageId
        data.admin_id = checkData(props.user) ? props.user.id : ''
        http.post(`pages/edit`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg, });
                onApiInteg(); setEditmodal(false);
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };

    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="border-0 shadow mb-3">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">Manage CMS Pages</CardTitle>
                </CardHeader>
            </Card>
            <Row>
                <div class="col-md-12 col-lg-12">
                    <div class="card events-card my-3">
                        <div class="card-header d-flex flex-wrap justify-content-between align-items-center">
                            <h3 class="mt-0">CMS Data</h3>
                            {checkData(props.permissions) && props.permissions.CMS_Pages.add &&
                                <Button color="primary" className="vs-btn border-0" onClick={() => toggleAdd()}>Add CMS</Button>
                            }
                        </div>
                        <CardBody>
                            <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="lg">
                                <ModalHeader toggle={toggleEdit}>Edit CMS</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={editSubmit(onEditPages)}>
                                        <Row className="mx-0  addModal">
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="title">Enter title<span className="text-danger">*</span></Label>
                                                    <input {...editInput("title", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter title*" id="title" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="slug">Enter slug<span className="text-danger">*</span></Label>
                                                    <input {...editInput("slug", { required: 'Please Enter Slug' })} disabled={slugs === "about-us" || slugs === "partners" ? true : false} className="form-control-sm form-control" placeholder="Enter Slug*" id="slug" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="large_description">Add CMS Content<span className="text-danger">*</span></Label>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={content}
                                                        tabIndex={1}
                                                        onBlur={newContent => setContent(newContent)}
                                                        onChange={newContent => { }}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <FormGroup check className="pl-0">
                                                        <Label>Status<span className="text-danger">*</span></Label>
                                                        <div>
                                                            <span className="form-check2 me-3">
                                                                <input className="mx-2" {...editInput("status", { required: true })} type="radio" value="1" id="active_add" />
                                                                <Label for="active_add">Active</Label>
                                                            </span>
                                                            <span className="form-check2">
                                                                <input className="mx-2" {...editInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                                <Label for="inactive_add">InActive</Label>
                                                            </span>
                                                        </div>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} className="text-right">
                                                <Button className="vs-btn border-0" color="primary">Save</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={addmodal} toggle={addmodal} scrollable={true} size="lg">
                                <ModalHeader toggle={toggleAdd}>Add New CMS</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={addSubmit(onAddPages)}>
                                        <Row className="mx-0  addModal">
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="title">Enter title<span className="text-danger">*</span></Label>
                                                    <input {...addInput("title", { required: 'Please Enter Title' })} className="form-control-sm form-control" placeholder="Enter title*" id="title" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="slug">Enter slug<span className="text-danger">*</span></Label>
                                                    <input {...addInput("slug", { required: 'Please Enter Slug' })} className="form-control-sm form-control" placeholder="Enter Slug*" id="slug" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="large_description">Edit CMS Content<span className="text-danger">*</span></Label>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={content}
                                                        tabIndex={1}
                                                        onBlur={newContent => setContent(newContent)}
                                                        onChange={newContent => { }}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <FormGroup check className="pl-0">
                                                        <Label>Status<span className="text-danger">*</span></Label>
                                                        <div>
                                                            <span className="form-check2 me-3">
                                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="1" id="active_add" />
                                                                <Label for="active_add">Active</Label>
                                                            </span>
                                                            <span className="form-check2">
                                                                <input className="mx-2" {...addInput("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                                                <Label for="inactive_add">InActive</Label>
                                                            </span>
                                                        </div>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} className="text-right">
                                                <Button className="vs-btn border-0" color="primary">Save</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ModalBody>
                            </Modal>
                            {(checkData(props.permissions) && props.permissions.CMS_Pages.list)?
                                <Table responsive className="text-center custom-table">
                                    <thead>
                                        <tr>
                                            <th className="text-nowrap">Title</th>
                                            <th className="text-nowrap">Slug</th>
                                            <th className="text-nowrap">Created At</th>
                                            <th className="text-nowrap">Updated At</th>
                                            <th className="text-nowrap">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {checkData(apiData) ? apiData.map((item, index) => (
                                            <tr>
                                                <td>{item.title}</td>
                                                <td>{item.slug}</td>
                                                <td>{changeIntoDatedmY(item.created_at)}</td>
                                                <td>{changeIntoDatedmY(item.updated_at)}</td>
                                                {checkData(props.permissions) && props.permissions.CMS_Pages.edit &&
                                                    <td><Button color="primary" className="vs-btn border-0" onClick={() => toggleEdit(item.id)}>Edit</Button></td>
                                                }
                                            </tr>
                                        )) : ''}
                                    </tbody>
                                </Table>
                            :
                            <PermissionNotAllowed/> }
                        </CardBody>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default CMS