import React, { useState } from 'react';
import { Form, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import http from '../../CommonAxios'

const SideForm = (props) => {
  const [loader, setLoader] = useState(false);

  const { register: contactInput, handleSubmit: contactSubmit, formState: { errors }, reset } = useForm();
  const onContactus = (data) => {
    setLoader(true);
    http.post(`contact/save`, data).then((res) => {
      setLoader(false);
      if (res.data.s === 1) {
        Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg, });
        reset();
      } else {
        console.log(res)
        Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
      }
    }).catch(function (err) {
      console.log('err', err)
        Swal.fire({ icon: 'error', title: 'Error', text: 'Something went wrong', });
        setLoader(false);
    });
  };
  const handleClick = event => {
    props.setSideAnim(false);
  }
  return (
    <div className={`right-side-anim  overflow-auto`}>
      {loader && <div className="formLoader"><Spinner /></div>}
      <section class="hidden-bar">
        <div class="inner-box">
          <div class="title-box">
            <h2>Contact Us</h2>
            <div class="cross-icon" onClick={handleClick}><span class="fa fa-times"></span></div>
          </div>
          <div class="form-style-one">

            <div class="wpcf7 js" id="wpcf7-f32-o1" lang="en-US" dir="ltr">
              <div class="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
              <Form onSubmit={contactSubmit(onContactus)} action="/newwp/volia/#wpcf7-f32-o1" method="post" class="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">

                <div class="form-group">
                  <p>
                    <input {...contactInput("name", { required: 'Please Enter Name' })} class="username" placeholder="Your Name *" />
                    {errors.name && <span className="text-danger d-block error">{errors.name.message}</span>}
                  </p>
                </div>
                <div class="form-group">
                  <p>
                    <input {...contactInput("email", { required: 'Please Enter Email' })} type="email" class="email" placeholder="Your Email *" />
                    {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                  </p>
                </div>
                <div class="form-group">
                  <p>
                    <input {...contactInput("phone", { required: 'Please Enter Phone' })} type="number" class="phone" placeholder="Your Phone*" required="" />
                    {errors.phone && <span className="text-danger d-block error">{errors.phone.message}</span>}
                  </p>
                </div>
                <div class="form-group">
                  <p>
                    <input {...contactInput("subject", { required: 'Please Enter Subject' })} class="username" placeholder="Subject *" />
                    {errors.subject && <span className="text-danger d-block error">{errors.subject.message}</span>}
                  </p>
                </div>
                <div class="form-group">
                  <p>
                    <textarea {...contactInput("message", { required: 'Please Enter Message' })} class="message" placeholder="Text Message"></textarea>
                    {errors.message && <span className="text-danger d-block error">{errors.message.message}</span>}
                  </p>
                </div>
                <div class="form-group">
                  <p>
                    <button class="theme-btn btn-style-one"><span class="btn-title">Submit Now</span> </button>
                  </p>
                </div>
              </Form>
            </div>
          </div>
          <ul class="contact-list-one">
            <li>
              <i class="fa fa-location-arrow"></i> CIDCO Exhibition & Convention Centre <strong>Address</strong>
            </li>
            <li>
              <i class="fa fa-clock"></i> November 24 & 25, 2023
            </li>
            <li>
              <i class="fa fa-envelope"></i> <a href="mailto:contact.gvs@iov.co.in">contact.gvs@iov.co.in</a> <strong>Mail to us</strong>
            </li>
          </ul>

        </div>
      </section>
    </div>
  );
}

export default SideForm;

SideForm.defaultProps = {
  position: "relative",
  toggleTrack: ""
};