import React from 'react'
import { Spinner } from 'reactstrap'

const Direction = () => {
    return (
        <section class="space-top space-extra-bottom">
        {/* {loader && <div className="formLoader"><Spinner /></div>} */}
            <div class="container-fluid">
                <div class="title-area2 text-center">
                    <span class="sub-title2">
                        Our<span className='newText'>Event Location</span>
                        {/* <img class="title-shape" src="./img/title_shape.png" alt="title shape" /> */}
                    </span>
                    <h2 class="sec-title">Get Direction To The Venue</h2>
                </div>
                <div class="row">
                    <div class="col-xl-4 d-none col-lg-5 mb-35 mb-lg-0">
                        <div class="location-card-wrap background-image" style={{backgroundImage: 'url(./img/location_box_bg.jpg)'}}>
                            <div class="vs-carousel slick-initialized slick-slider" data-asnavfor="#mapSlide" id="locationSlide" data-fade="true" data-slide-show="1" data-md-slide-show="1">
                                <div class="slick-list draggable"><div class="slick-track" style={{opacity: 1, width: '574px'}}>
                                    <div class="location-card slick-slide slick-current slick-active" style={{width: '287px', position: 'relative', left: '0px', top: '0px', zIndex: 999, opacity: 1}} tabindex="0" data-slick-index="0" aria-hidden="false">
                                    <h3 class="location-title">Venue</h3>
                                    <div class="location-info">
                                        <span class="info-title">CIDCO Exhibition & Convention Centre</span>
                                        <p class="info-text">Swami Pranabananda Marg, Sector 30-A, Vashi, Navi Mumbai, Maharashtra - 400703 </p>
                                    </div>
                                    <a href="https://goo.gl/maps/EypUdieyLGzzdzP97?coh=178571&entry=tt" target="_blank" class="vs-btn btn-white" tabindex="0">Get Directions</a>
                                  </div><div class="location-card slick-slide" style={{width: '287px', position: 'relative', left: '-287px', top: '0px', zIndex: 998, opacity: 0, transition: 'opacity 1000ms ease 0s'}} tabindex="-1" data-slick-index="1" aria-hidden="true">
                                        <h3 class="location-title">Branch Two</h3>
                                        <div class="location-info">
                                            <span class="info-title">Venue</span>
                                            <p class="info-text">Grand Event Hall</p>
                                        </div>
                                        <div class="location-info">
                                            <span class="info-title">Address</span>
                                            <p class="info-text">Guhawati, <br /> India</p>
                                        </div>
                                        <a href="https://www.google.com/maps/d/viewer?mid=1U8NwBvkM-5va85P8XZr3t_HLIsA_tOg&ll=26.146310677722912%2C91.74620054228158&z=11" target="_blank" class="vs-btn btn-white d-none" tabindex="-1">Get Directions</a>
                                      </div>
                                    </div>
                                    </div></div>
                            <div class="vs-icon-box d-none">
                                <button data-slick-prev="#locationSlide" class="simple-icon arrow-left"><i class="fa fa-arrow-left"></i></button>
                                <button data-slick-next="#locationSlide" class="simple-icon arrow-right"><i class="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 p-0">
                        <div class="map-slide-wrap">
                            <div class="vs-carousel slick-initialized slick-slider" data-asnavfor="#locationSlide" id="mapSlide" data-fade="true" data-slide-show="1" data-md-slide-show="1">

                                <div class="slick-list draggable"><div class="slick-track" style={{opacity: 1, width: '1608px'}}>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15083.690624832569!2d72.9950437!3d19.0671379!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1854ae98517%3A0x68f18a54075c03c1!2sCIDCO%20Exhibition%20%26%20Convention%20Centre!5e0!3m2!1sen!2sin!4v1684231704473!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="slick-slide slick-current slick-active" style={{width: '100%',height: '514px', position: 'relative', left: '0px', top: '0px', zIndex: 999, opacity: 1}} tabindex="0" data-slick-index="0" aria-hidden="false"></iframe>
                                </div>
                                </div></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Direction
